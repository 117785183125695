import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom"
import StepsHeader from '../../Utils/StepsHeader';
import { commonPostApi, dkycApi, dkycApiWithLoader, getKycJourney } from '../../../ApiServices/common';
import { startLoaderAction, stopLoaderAction } from '../../../redux/actions/app.action';
import { useResumeJourneyPerStage } from '../../Utils/useResumeJourneyPerStage';
import validator from 'validator';
import AsideWallpaper from '../../Utils/AsideWallpaper';
import { setEducationAction, setExperienceAction, setIncomeAction, setIncomeDescription, setIncomeDescriptionAction, setOccupationAction, setOccupationDescriptionAction } from '../../../redux/actions/kyc/step4.action';
import axios from 'axios';
import { useCheckRequiredJourneyData } from '../../Utils/useCheckRequiredJourneyData';
import { addressCodes, stateCodes } from '../../../common/data/kraData';

function PersonalDetails1() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const kycStep1 = useSelector(state => state?.ekycStep1 || {});
  const kycStep2 = useSelector(state => state?.ekycStep2 || {});
  const kycStep3 = useSelector(state => state?.ekycStep3 || {});
  const kycStep4 = useSelector(state => state?.ekycStep4 || {});
  const userDetails = useSelector(state => state?.userLogin || {});
  const [pan, setPan] = useState(kycStep1.panInput.value);
  const [panHolderFullName, setPanHolderFullName] = useState(kycStep1?.userData?.fullName);
  const [email, setEmail] = useState(userDetails?.emailInput);
  const [buttonDisable, setButtonDisable] = useState(true);
  const apiKey = process.env.REACT_APP_DKYC_BONANZA_API_KEY;
  const isKraApproved = kycStep3?.isKraApproved;
  
  let initalStageforPersonalDetails = '6';
  useCheckRequiredJourneyData(initalStageforPersonalDetails);

  const navigationStage = (stage) => {
    switch (stage) {
      case '1':
        navigate('/');
        break;
      case '2':
        navigate('/email-input');
        break;
      case '3':
        navigate('/email-input');
        break;
      case '4':
        navigate('/pan-verification');
        break;
      case '5':
        navigate('/pan-verification');
        break;
      case '6':
        navigate('/personal-details-1');
        break;
      case '7':
        navigate('/occupation-details');
        break;
      case '8':
        navigate('/find-bank-details');
        break;
      case '9':
        navigate('/segment-details');
        break;
      case '10':
        navigate('/bank-proof');
        break;
      case '11':
        navigate('/income-proof');
        break;
      case '12':
        navigate('/selfie');
        break;
      case '13':
        navigate('/draw-sign');
        break;
      case '14':
        navigate('/e-sign');
        break;
      case '15':
        navigate('/welcome-page')
        break;
      case '16':
        navigate('/thanks-page');
        break;
      default:
        navigate('/');
    }
  }

  useEffect(() => {
    if (isKraApproved) {
      setAddress(kycStep3?.PRAddr1 + " " + kycStep3?.PRAddr2 + "" + kycStep3?.PRAddr3.replace(/\n\s+/g, '') + ", " + kycStep3?.PRCity + ", " + kycStep3?.PRPinCode + ", " + findStateName(String(kycStep3?.PRState.replace(/\n\s+/g, '') || '')));
      console.log(
        kycStep3.perAddressRef,
        kycStep3.perAddressProof,
        kycStep3.corAddressRef,
        kycStep3.corAddressProof,
        kycStep3?.uniqueKey, 'proof types'
      );
    } else {
      const fetchData = async () => {
        const mobile = userDetails?.mobileInput;
        const payload = {
          mobile: mobile
        };
        try {
          const response = await dkycApi('/getKycJourney', payload, 'multipart/form-data');
          if (true) {
            const jsonData = response?.data[0]?.json_data;
            const json_data = JSON.parse(jsonData);
            setAadhar(json_data?.actions[0]?.details?.aadhaar?.id_number);
            setPincode(json_data?.actions[0]?.details?.aadhaar?.permanent_address_details.pincode);
            setState(json_data?.actions[0]?.details?.aadhaar?.permanent_address_details.state);
            setCity(json_data.actions[0]?.details?.aadhaar?.permanent_address_details.district_or_city);
            setAddress(json_data.actions[0]?.details?.aadhaar?.permanent_address);
            // navigationStage(response?.stage);
          }
        } catch (err) {
          setErrorMsg('Your Data can not fetched !!')
        }
      };
      fetchData();
    }
  }, [kycStep3?.isKraApproved]);

  useEffect(() => {


    const GetFamilyInfoPayload = {
      "PanNo": pan,
      "Email": email,
      "MobileNumber": userDetails?.mobileInput
    }

    let relationsArray = [];
    dkycApiWithLoader('/GetFamilyInfo', GetFamilyInfoPayload, 'application/json')
      .then((response) => {
        if (response.success === true) {
          console.log(response);
          let latestDetails = [];
          let relationId = 1;

          response?.data.forEach((value) => {
            let familyObject = {
              'Name': value.name,
              'ClientCode': value.oowncode,
              'RelationId': value.Relation_Id
            };
            relationsArray.push(familyObject);
          });
          latestDetails = {
            'Name': panHolderFullName,
            'ClientCode': pan,
            'RelationId': relationId,
          };
          relationsArray.push(latestDetails);
          const RelationData = {
            "PanNo": pan,
            "Email": email,
            "MobileNumber": userDetails?.mobileInput,
            "FamilyRelation": relationsArray,
          }
          dkycApiWithLoader('/SaveFamilyRelation', RelationData, 'application/json')
            .then((response) => { console.log(response) })
            .catch((err) => {
              setErrorMsg(err);
              setButtonDisable(true);
            })
        } else {
          setErrorMsg(response?.message);
          setButtonDisable(true);
        }
      })
      .catch((err) => {
        setErrorMsg(err);
        setButtonDisable(true);
      })

  }, [])


  const findStateName = (stateCode) => {
    const state = stateCodes.find((s) => s.code === stateCode);
    if (state) {
      return state?.description;
    }
    // Return a default value or handle the case when the state code is not found.
    return 'State Not Found';
  };

  const findAddressProof = (code) => {
    const addressProof = addressCodes.find((proof) => proof.code === code);
    if (addressProof) {
      return addressProof.description;
    }
    return 'Address Proof Not Found';
  };

  const [selectedGender, setSelectedGender] = useState(kycStep3?.gender === "\n    " ? '' : kycStep3?.gender);
  const [maritalStatus, setMaritalStatus] = useState(kycStep3?.marital === "\n    " ? '' : kycStep3?.marital);
  // const [maritalStatus, setMaritalStatus] = useState(kycStep3?.marital == "01" ? "Married" : "Single");
  const [fatherSpouseName, setFatherSpouseName] = useState(kycStep3?.father_spouse === "\n    " ? '' : kycStep3?.father_spouse);
  const [birthPlace, setBirthPlace] = useState(kycStep3?.birthplace === "\n    " ? '' : kycStep3?.birthplace);
  const [isIndian, setIsIndian] = useState(kycStep3?.nationality);
  const [errorMsg, setErrorMsg] = useState('');
  const [genderError, setGenderError] = useState('');
  const [maritalError, setMaritalError] = useState('');
  const [birthplaceError, setBirthplaceError] = useState('');
  const [spouseError, setSpouseError] = useState('');
  const [aadhar, setAadhar] = useState('');
  const [pincode, setPincode] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const occ = kycStep4.occupation;
  const income = kycStep4.income;


  const setOccupationDetails = async (occCode) => {
    const url = process.env.REACT_APP_DKYC_BONANZA_OCCUPATION_CODE_URL;
    const headers = {
      "Accept": "application/json",
      Authorization: `Bearer ${apiKey}`
    }
    axios.post(
      url,
      { "cvl_code": occCode },
      { headers }
    )
      // dkycApiWithLoader('/GetKycOccuptionFromCvl', { "cvl_code": occCode }, "application/json")
      .then((response) => {
        const data = response.data
        if (data.success) {
          dispatch(setOccupationAction(data.data[0]['occupation_id']));
          dispatch(setOccupationDescriptionAction(data.data[0]['occupation_name']));
        } else {
          dispatch(setOccupationAction(""));
          dispatch(setOccupationDescriptionAction(""))

        }
      })
      .catch((error) => {
        // setErrorMsg('Some Error Has Occured');
        dispatch(setOccupationAction(""));
        dispatch(setOccupationDescriptionAction(""))
      })
  }
  const setIncomeCategory = async (incCode) => {
    const url = process.env.REACT_APP_DKYC_BONANZA_INCOME_CODE_URL;
    const headers = {
      "Accept": "application/json",
      Authorization: `Bearer ${apiKey}`
    }
    await axios.post(
      url,
      { "cvl_code": incCode },
      { headers }
    )
      // dkycApiWithLoader('/GetKycIncomeFromCvl', { "cvl_code": incCode }, "application/json")
      .then((response) => {
        const data = response.data;
        if (data.success) {
          dispatch(setIncomeAction(data.data[0]['kyc_income_id']));
          dispatch(setIncomeDescriptionAction(data.data[0]['description']));
        } else {
          dispatch(setIncomeAction(""));
          dispatch(setIncomeDescriptionAction(""));
        }
      })
      .catch((error) => {
        // setErrorMsg('Some Error Has Occured');
        dispatch(setIncomeAction(""));
        dispatch(setIncomeDescriptionAction(""));
      })
  }


  useEffect(() => {
    if (occ) {
      setOccupationDetails(occ.padStart(2, '0'));
    }
    if (income) {
      setIncomeCategory(income.padStart(2, '0'));
    }
  }, [])

  const handleMaritalStatusDropdown = (e) => {
    const maritalStatus = e.target.value;
    setMaritalStatus(maritalStatus)
  }
  const handleGenderDropdown = (e) => {
    setSelectedGender(e.target.value);
  }

  const fatherSpouceNameHandler = (e) => {
    if (e.target.value.length < 3 && e.target.value.length > 0) { setSpouseError('Father/Spouse name must me atleast 3 characters') }
    else { setSpouseError('') }
    if (/^[a-zA-Z\s]+$/.test(e.target.value) || e.target.value === '') { setFatherSpouseName(e.target.value) }
  }
  const onChangeHandlerNext = async () => {
    setErrorMsg('');
    if (kycStep3?.isKraApproved) {
      const Payload = {
        "mobile": userDetails.mobileInput,
        "marital": maritalStatus == '01' ? 'Married' : 'Single',
        "FamilyType": "Father",
        "father_spouse": fatherSpouseName.replace(/\n\s+/g, ''),
        "birthplace": birthPlace.replace(/\n\s+/g, ''),
        "gender": selectedGender.replace(/\n\s+/g, ''),
        aadhar: '',
        // "aadhar":  kycStep3.perAddressProof == 31 ? kycStep3?.perAddressRef.replace(/\n\s+/g, '') : '',
        //what to enter city,state and pincode 
        "city": kycStep3?.PRCity.replace(/\n\s+/g, ''),
        "state": findStateName(String(kycStep3?.PRState.replace(/\n\s+/g, '') || '')),
        "pincode": kycStep3?.PRPinCode.replace(/\n\s+/g, ''),
        "apiType": "1",
        "CRAddr1": kycStep3?.CRAddr1.replace(/\n\s+/g, ''),
        "CRAddr2": kycStep3?.CRAddr2.replace(/\n\s+/g, ''),
        "CRAddr3": kycStep3?.CRAddr3.replace(/\n\s+/g, ''),
        "CRPinCode": kycStep3?.CRPinCode,
        "PRAddr1": kycStep3?.PRAddr1.replace(/\n\s+/g, ''),
        "PRAddr2": kycStep3?.PRAddr2.replace(/\n\s+/g, ''),
        "PRAddr3": kycStep3?.PRAddr3.replace(/\n\s+/g, ''),
        "CRCity": kycStep3?.CRCity.replace(/\n\s+/g, ''),
        "CRState": findStateName(String(kycStep3?.CRState.replace(/\n\s+/g, '') || '')),
        "PRCity": kycStep3?.PRCity.replace(/\n\s+/g, ''),
        //state
        "PRState": findStateName(String(kycStep3?.PRState.replace(/\n\s+/g, '') || '')),
        "PRPinCode": kycStep3?.PRPinCode,
        "app_status": kycStep3?.app_status,
        "app_update_status": kycStep3?.app_update_status,
        "uniqueKey": kycStep3?.uniqueKey,
        "PeraddressProof": findAddressProof(kycStep3?.perAddressRef),
        "CorAddressProof": findAddressProof(kycStep3?.corAddressRef),
        "PerAddressType": kycStep3?.perAddressProof,
        "CorAddressType": kycStep3?.corAddressProof

        // setUniqueKey(data?.APP_PAN_SUMM?.APP_OTHKRA_BATCH || 'NA');
        // setPerAddressProof(Object.keys(data?.KYC_DATA?.APP_PER_ADD_PROOF).length === 0 ? 'NA' : data?.KYC_DATA?.APP_PER_ADD_PROOF);
        // setCorAddressProof(Object.keys(data?.KYC_DATA?.APP_COR_ADD_PROOF).length === 0 ? 'NA' : data?.KYC_DATA?.APP_COR_ADD_PROOF);
        // setPerAddressType(data?.KYC_DATA?.APP_PER_ADD_REF === 'NA' || data?.KYC_DATA?.APP_PER_ADD_REF === '' || Object.keys(data?.KYC_DATA?.APP_PER_ADD_REF).length === 0 ? 'NA' : ` ${data?.KYC_DATA?.APP_PER_ADD_REF}`);
        // setCorAddressType(data?.KYC_DATA?.APP_COR_ADD_REF === 'NA' || data?.KYC_DATA?.APP_COR_ADD_REF === '' || Object.keys(data?.KYC_DATA?.APP_COR_ADD_REF).length === 0 ? 'NA' : ` ${data?.KYC_DATA?.APP_COR_ADD_REF}`);

        // 'PR_full_address' : kycStep3?.PRAddr1.replace(/\n\s+/g, '') + kycStep3?.PRAddr2.replace(/\n\s+/g, '') + kycStep3?.PRAddr3.replace(/\n\s+/g, ''),
        // 'CR_full_address' : kycStep3?.CRAddr1.replace(/\n\s+/g, '') + kycStep3?.CRAddr2.replace(/\n\s+/g, '') + kycStep3?.CRAddr3.replace(/\n\s+/g, ''),

      }
      // let isValid = validatePersonalDataRequest(Payload);
      // if (isValid) {
      if (true) {
        console.log(Payload, 'krapayload');
        commonPostApi('/save-personal-details-web', Payload, null)
          .then((response) => {
            console.log(Payload, 'krapayload');
            if (response?.status === 200) {
              navigationStage(response?.user_data?.stage);
              //  navigate('/occupation-details');
            }
            else { setErrorMsg(response?.msg ? response?.msg : "Something went wrong !!"); }
          })
          .catch((error) => {
            setErrorMsg(error);
          })
      }
    }
    else {
      const Payload = {
        "mobile": userDetails?.mobileInput,
        "marital": maritalStatus == '01' ? 'Married' : 'Single',
        "FamilyType": maritalStatus == 'Married' ? 'Spouse' : 'Father',
        "father_spouse": fatherSpouseName,
        // "birthplace": birthPlace ,
        "birthplace": city,
        "gender": selectedGender,
        "aadhar": aadhar,
        "city": city,
        "state": state,
        "pincode": pincode,
        "apiType": "2"
      }
      // let isValid = validatePersonalDataRequest(Payload);
      // if (isValid) {
      if (true) {
        commonPostApi('/save-personal-details-web', Payload, null)
          .then((response) => {
            if (response.status === 200) {
              navigationStage(response?.user_data?.stage);
              //  navigate('/occupation-details');
            }
            else { setErrorMsg(response?.msg ? response?.msg : "Something went wrong,please try again later"); }
          })
          .catch((error) => {
            setErrorMsg(error);
          })
      }
    }
  }
  useEffect(() => {
    if (maritalStatus && selectedGender && fatherSpouseName?.length > 2) { setButtonDisable(false); }
    else { setButtonDisable(true); }
  }, [maritalStatus, selectedGender, fatherSpouseName])


  return (<>
    <section className="main-wraper progress-inner">
      <StepsHeader step={3} />
      <AsideWallpaper
        color={'green'}
        descriptionHeader={''}
        backGroundImageUrl={"images/personal-details.svg"}
      />
      <div className="body-content-box">
        <div className="login-box">
          <div className="login-box-wrap">
            <div className="">
              <h1 className="title-1 mb-h740-2">Fill your Details </h1>
            </div>
            <div className="mw-400 block-center">
              <p className="para-1 mb-h740-2">Personal Details - 1/2</p>
              <p className="mb-4 mb-h740-3 checkbox-nationality">
                Nationality :
                <label className="checkbox-container">
                  <input type="checkbox" defaultChecked={true} onChange={(e) => { setIsIndian(e.target.checked) }} disabled={kycStep3.isKraApproved} />
                  <span className="checkmark" />
                  <span className="checkbox-text">Indian</span>
                </label>
              </p>
            </div>
            <div className="input-box">
              <div className="text-danger">{genderError}</div>
              <div className="custom-select mb-2">
                <select className="input-form text-center mb-3" value={selectedGender} onChange={handleGenderDropdown}
                  disabled={kycStep3?.isKraApproved && selectedGender}>
                  <option className="color-black" value=''>Your Gender</option>
                  <option value="M" >Male</option>
                  <option value="F">Female</option>
                  <option value="T">Transgender</option>
                </select>
              </div>
              <div className="text-danger">{maritalError}</div>
              <div className="custom-select mb-2">
                <select className="input-form text-center mb-3" value={maritalStatus} onChange={handleMaritalStatusDropdown}
                // disabled={kycStep3?.isKraApproved && maritalStatus}
                >
                  <option className="color-black" value=''>Your Marital Status</option>
                  <option value="02">Single</option>
                  <option value="01">Married</option>
                </select>
              </div>
              <div className="text-danger font-14">{spouseError}</div>
              <input
                type="text"
                className="input-form text-center mb-3"
                placeholder="Father’s/ Spouse’s Name"
                onChange={fatherSpouceNameHandler}
                value={fatherSpouseName}
                disabled={kycStep3?.isKraApproved && fatherSpouseName}
                error_message={spouseError}
              />
              {/* <div className="text-danger">{birthplaceError}</div>
              <input
                type="text"
                className="input-form text-center mb-3 mb-h740-2"
                placeholder="City of Birth"
                value={birthPlace}
                onChange={(e) => {
                  if (/^[a-zA-Z\s]+$/.test(e.target.value) || e.target.value === '') { setBirthPlace(e.target.value) }
                }}
                disabled={kycStep3?.isKraApproved && birthPlace}
              /> */}

              <textarea
                type="text"
                className="input-form-address"
                placeholder="Address"
                value={address}
                // style={{height: "auto" ,width : "100%" ,borderRadius : "25px"}}
                disabled={true}
              />
            </div>
          </div>
          {errorMsg && <div className='input-error-msg text-center'>{errorMsg}</div>}
          <div className="btn-action-box">
            <button className={`btn btn-${buttonDisable ? 'secondary' : 'primary'} btn-full mb-2`} onClick={onChangeHandlerNext} disabled={buttonDisable}>
              Next, Occupation Details
            </button>
          </div>
        </div>
      </div>
    </section>

  </>)


}
export default PersonalDetails1;

