import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom"
import { changeStep1InputAction, setMobileTimerAction } from '../../../redux/actions/kyc/userlogin.action';
import { persistor, store } from '../../../redux/store';
import { commonPostApi } from '../../../ApiServices/common';
import { resetState, stopLoaderAction } from '../../../redux/actions/app.action';
import AsideWallpaper from '../../Utils/AsideWallpaper';
import { useHistory, useLocation } from 'react-router-dom';
import { resetStateAction } from '../../../redux/actions/reset.action';
import Encripted from '../../Utils/Encrypt';

function IndexPage() {

  console.log('build chanages detected');
  const location = useLocation()
  const [errorMsg, setErrorMsg] = useState('');
  const navigate = useNavigate();
  const Dispatch = useDispatch();
  const userLogin = useSelector(state => state?.userLogin || {});
  const Error = useSelector(state => state.error);
  const utmGetData = sessionStorage.getItem('utmGetData') ? sessionStorage.getItem('utmGetData') : window.location.href;
  const url = new URL(window.location.href);
  const utmType = url?.searchParams.get('utm_type') !== '' && url?.searchParams.get('utm_type') !== null && url?.searchParams.get('utm_type') !== undefined ? url?.searchParams.get('utm_type') : sessionStorage.getItem('utm_type');
  // const utmType = sessionStorage.getItem('utm_type') ;
  const inputRef = useRef(null);
  const [Tnc, SetTnc] = useState(true);
  const TnCHandler = () => {
    SetTnc(!Tnc);
  }
  useEffect(() => {
    Dispatch(resetStateAction());
  }, []);
  // useEffect(()=>{Encripted([{"panNo":'fsfsf' , "dob":'rttrt' ,"name":'dgdfgdg'}]),[]});
  useEffect(()=>{
    if(Tnc === false);

    // setErrorMsg('please accept Terms and condition to proceed with onBoarding Journey');
  },[Tnc])

  const handleInputChange = (e) => {
    setErrorMsg('');
    const value = e.target.value
    const numericValue = value.replace(/\D/g, '').slice(0, 10);
    Dispatch(changeStep1InputAction(numericValue));
  }

  const onInputHandler = async () => {
    setErrorMsg('');
    const mobileInput = userLogin.mobileInput || '';
    const regex = /^(?=[6-9])\d{10}$/;
    const ValidNo = regex.test(userLogin.mobileInput);
    if (ValidNo === false) { setErrorMsg('Please enter a valid mobile number'); return; }
    const data = {
      mobile: mobileInput,
      mx_Source_URL: utmGetData,
      utm_get_data: utmGetData?.split("?")[1] ? utmGetData?.split("?")[1] : '',
      client_type: utmType == 'partner' ? 'partner' : 'client'
    }
    commonPostApi('/login-signup-web', data, null)
      .then((data) => {
        if (data.status === 200) {
          sessionStorage.removeItem('utmGetData');
          sessionStorage.removeItem('utm_type');
          navigate('/mobile-verification');
        } else {
          if(data.status === 500 && data.type === "newekyc"){
            window.location.href = data.redirectUrl;
            return;
          }
          setErrorMsg(data?.msg ? data?.msg : "Some error occured while sending OTP, please try again");
        }
      })
      .catch((error) => {
        setErrorMsg(error);
      })
  }
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onInputHandler();
    }

  }
  return (<>
    <section className="main-wraper index-page">
      <header className="site-header">
        <div className="site-header-wrap">
          <div className="logo-box w-100 text-center">
            <a>
              <img src="/images/Bigul-logo.svg" alt="image" />
            </a>
          </div>
        </div>
      </header>
      <AsideWallpaper
        color={'blue'}
        descriptionHeader={''}
        backGroundImageUrl={"./images/otp-image.svg"}
      />
      <div className="body-content-box">
        <div className="login-box">
          <div className="login-box-wrap mw-468">
            <div className="title-box">
              <p className="index-sub-title mw-468">
                A Trading and Investment Ecosystem built for your Success!{" "}
              </p>
            </div>
            <div className="form-box">
              <p className="color-blue" >Let’s get started!</p>
            </div>
            <div className={`input-box position-relative get-otp  ${inputRef ? 'xyz' : ''}`}>
              <input
                type='tel'
                autoFocus
                className="input-form"
                placeholder="Enter Mobile Number"
                value={userLogin?.mobileInput}
                pattern="[0-9]{10}"
                onChange={handleInputChange}
                maxLength={10}
                ref={inputRef}
                onKeyDown={handleKeyPress}
              />
              <button
                type="button"
                className= {`input-submit ${Tnc ? 'input-get-otp' : 'input-get-otp-disable'} `} 
                onClick={onInputHandler}
                disabled = {!Tnc}
              >Get OTP </button>

              {errorMsg && <div className="input-error-msg">{errorMsg}</div>}
            </div>
            <p className="para-1">Please keep your <b>Permanent Account Number (PAN),</b> Aadhar Number and <b>Bank Account Details </b>ready for the fast completion.</p>
            {/* <div className="or-box position-relative">
              <span className="position-relative">OR</span>
            </div> */}
          </div>
          {/* <button variant="primary" className="google-signin-box" onClick={handleReset}>
            <img
              src="./images/logo-google.svg"
              alt="google account image"
            />
            <p>Sign in with Google </p>
          </button> */}
          <label className="d-flex" style={{justifyContent: "center" ,marginBottom : "10px"}}>
            <input type="checkbox" checked={Tnc} onChange={TnCHandler} />
            <span className="para-13 ml-1 d-inline-block mr-4"> I accept the
              <a href='https://bigul.co/terms-and-conditions/kyc-terms-and-conditions/' target="_blank" style={{ textDecoration: "none" }}> Terms &amp; Conditions  </a>
              and
              <a href='https://bigul.co/policy/privacy-policy' target="_blank" style={{ textDecoration: "none" }}>  Privacy Policy</a>
            </span>
          </label>
        </div>
      </div>
    </section>

  </>)
}
export default IndexPage;