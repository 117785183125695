import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import { commonPostApi, dkycApi, dkycApiWithLoader, dkycDirectGetApi, dkycGetApi, esignApi, getKycJourney } from '../../../ApiServices/common';
import { useSelector } from 'react-redux';
import { stopLoaderAction } from '../../../redux/actions/app.action';
import { store } from '../../../redux/store';
import StepsHeader from '../../Utils/StepsHeader';
import { useResumeJourneyPerStage } from '../../Utils/useResumeJourneyPerStage';
import AsideWallpaper from '../../Utils/AsideWallpaper';
import axios from 'axios';
import { useCheckRequiredJourneyData } from '../../Utils/useCheckRequiredJourneyData';
import ReactInputMask from 'react-input-mask';
import { SmallLoader } from '../../loader/SmallLoader';

import { Button } from 'bootstrap';

function TradingAndInvestmentPreferences() {
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1; // Months are zero indexed
    let day = today.getDate();

    // Pad single digit months/days with leading zeros
    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }

    return `${year}-${month}-${day}`;
  };

  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(true);
  const [isDdpiToggle, setIsDdpiToggle] = useState(false);
  const [isDeclarationToggle, setIsDeclarationToggle] = useState(false);
  const [TnC, setTnC] = useState(false);
  const [nextButtonDesc, setNextButtonDesc] = useState('Next, Selfie');
  const [navigateDesc, setNavigateDesc] = useState('selfie');
  const [fileId, setFileId] = useState(null);
  const [IsKraApproved, setIsKraApproved] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const userDetails = useSelector(state => state?.userLogin || {});
  const kycStep1 = useSelector(state => state?.ekycStep1 || {});
  const [pan, setPan] = useState(kycStep1.panInput.value);
  const [selectedOption, setSelectedOption] = useState('option1'); // Default selected option
  const [selectedOption2, setSelectedOption2] = useState('Electronically');
  const mobile = userDetails.mobileInput;
  const [declarations, setDeclarations] = useState([])
  const [hasNominee, setHasNominee] = useState('no');
  const [nomineeDataList, setNomineeDataList] = useState({}); // State to store nominee data
  const [isToggledNominee, setIsToggledNominee] = useState(true);
  const [isToggledNominee1, setIsToggledNominee1] = useState(false);
  const [isToggledNominee2, setIsToggledNominee2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [smallLoaderActive, setSmallLoaderActive] = useState(false);
  const [smallLoaderActive1, setSmallLoaderActive1] = useState(false);
  const [smallLoaderActive2, setSmallLoaderActive2] = useState(false);
  const [nomineeData, setNomineeData] = useState({
    fullName: '',
    dob: '',
    email: '',
    mobile: '',
    sharing: 100,
    relation: '',
    identification: '',
    identificationNumber: '',
    sameAddress: true,
    address1: '',
    address2: '',
    address3: '',
    pincode: '',
    state: '',
    city: ''
  });
  const [nomineeData1, setNomineeData1] = useState({
    fullName1: '',
    dob1: '',
    email1: '',
    mobile1: '',
    sharing1: '',
    relation1: '',
    identification1: '',
    identificationNumber1: '',
    sameAddress1: false,
    address11: '',
    address21: '',
    address31: '',
    pincode1: '',
    state1: '',
    city1: ''
  });
  const [nomineeData2, setNomineeData2] = useState({
    fullName2: '',
    dob2: '',
    email2: '',
    mobile2: '',
    sharing2: '',
    relation2: '',
    identification2: '',
    identificationNumber2: '',
    sameAddress2: false,
    address12: '',
    address22: '',
    address32: '',
    pincode2: '',
    state2: '',
    city2: ''
  });
  const transformNomineeData = (data, index) => {
    const keys = {
      fullName: `fullName${index}`,
      dob: `dob${index}`,
      email: `email${index}`,
      mobile: `mobile${index}`,
      relation: `relation${index}`,
      identification: `identification${index}`,
      identificationNumber: `identificationNumber${index}`,
      sameAddress: `sameAddress${index}`,
      address1: `address1${index}`,
      address2: `address2${index}`,
      address3: `address3${index}`,
      pincode: `pincode${index}`,
      state: `state${index}`,
      city: `city${index}`,
      sharing: `sharing${index}`
    };

    return {
      fullName: data[keys.fullName],
      dob: data[keys.dob],
      email: data[keys.email],
      mobile: data[keys.mobile],
      relation: data[keys.relation],
      identification: data[keys.identification],
      identificationNumber: data[keys.identificationNumber],
      sameAddress: data[keys.sameAddress],
      address1: data[keys.address1],
      address2: data[keys.address2],
      address3: data[keys.address3],
      pincode: data[keys.pincode],
      state: data[keys.state],
      city: data[keys.city],
      sharing: Number(data[keys.sharing])
    };
  };
  const handleToggleNominee = () => {
    setIsToggledNominee(!isToggledNominee);
  };
  const handleToggleNominee1 = () => {
    setIsToggledNominee1(!isToggledNominee1);
  };
  const handleToggleNominee2 = () => {
    setIsToggledNominee2(!isToggledNominee2);
  };

  const handleNomineeRadioChange = (e) => {
    setHasNominee(e.target.value);
    if (e.target.value == "no") {
      setNomineeData({
        fullName: '',
        dob: '',
        email: '',
        mobile: '',
        sharing: 100,
        relation: '',
        identification: '',
        identificationNumber: '',
        sameAddress: false,
        address1: '',
        address2: '',
        address3: '',
        pincode: '',
        state: '',
        city: ''
      })
      setErrors({});
      setNomineeData2({
        fullName2: '',
        dob2: '',
        email2: '',
        mobile2: '',
        sharing2: 0,
        relation2: '',
        identification2: '',
        identificationNumber2: '',
        sameAddress2: false,
        address12: '',
        address22: '',
        address32: '',
        pincode2: '',
        state2: '',
        city2: ''
      })
      setErrors1({});
      setNomineeData1({
        fullName1: '',
        dob1: '',
        email1: '',
        mobile1: '',
        sharing1: 0,
        relation1: '',
        identification1: '',
        identificationNumber1: '',
        sameAddress1: false,
        address11: '',
        address21: '',
        address31: '',
        pincode1: '',
        state1: '',
        city1: ''
      })
      setErrors2({});
    }
  };
const [showModal , setShowModal] = useState(false);
const modalToggle =()=>{
  setShowModal(!showModal);
}
  //resume journey
  const initalStageforDDPIDetails = '9';
  // useResumeJourneyPerStage(initalStageforDDPIDetails);
  // useCheckRequiredJourneyData(initalStageforDDPIDetails);
  const initializeSelectedOptions = (declarations) => {
    const options = {};
    declarations.forEach((declaration, index) => {
      if (declaration.options && declaration.options.length > 0) {
        options[`declaration${index + 1}_option`] = declaration.options[0];
      }
    });
    return options;
  };
  useEffect(() => {
    dkycGetApi('/GetDeclarationPreferences')
      .then((response) => {
        setPEPDesc(response.ekyc_data[10].description);
        setDeclarations(response.ekyc_data);
        setSelectedOptions(initializeSelectedOptions(response.ekyc_data));
      })
      .catch(() => { })
  }, [])

  const [declaration13Input, setDeclaration13Input] = useState('');
  const [isPEPChecked, setIsPEPChecked] = useState(false);
  const [PEPDesc, setPEPDesc] = useState('');
  const [pepDescription, setPEPDescription] = useState('dont check the pep description');
  //navigateJourney
  const navigationStage = (stage) => {
    switch (stage) {
      case '1':
        navigate('/');
        break;
      case '2':
        navigate('/email-input');
        break;
      case '3':
        navigate('/email-input');
        break;
      case '4':
        navigate('/pan-verification');
        break;
      case '5':
        navigate('/pan-verification');
        break;
      case '6':
        navigate('/personal-details-1');
        break;
      case '7':
        navigate('/occupation-details');
        break;
      case '8':
        navigate('/find-bank-details');
        break;
      case '9':
        navigate('/segment-details');
        break;
      case '10':
        navigate('/bank-proof');
        break;
      case '11':
        navigate('/income-proof');
        break;
      case '12':
        navigate('/selfie');
        break;
      case '13':
        navigate('/draw-sign');
        break;
      case '14':
        navigate('/e-sign');
        break;
      case '15':
        navigate('/welcome-page')
        break;
      case '16':
        navigate('/thanks-page');
        break;
      default:
        navigate('/');
    }
  }

  //setting trade checkboxes
  const [masterTradeChecked, setMasterTradeChecked] = useState(true);
  const [checkboxes, setCheckboxes] = useState({
    nseEquity: true,
    bseEquity: true,
    futuresOptions: false,
    BsefuturesOptions: false,
    commoditiesCurrencies: false,
    commodities: false,
    mutualFunds: false,

  });
  //terms and conditions
  const handleMasterCheckboxChange = (event) => {
    const checked = event.target.checked;
    setMasterTradeChecked(checked);
    setMasterEquityChecked(checked);
    // Set the state of all checkboxes based on the master checkbox
    setCheckboxes((prevState) => ({
      ...prevState,
      nseEquity: checked,
      bseEquity: checked

    }));
  };
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    // Check if Futures & Options checkbox is checked

    // Update the state of the clicked checkbox
    setCheckboxes((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };
  const isNseChecked = checkboxes?.nseEquity;
  const isBseChecked = checkboxes?.bseEquity;
  const isFuturesOptionsChecked = checkboxes?.futuresOptions;
  // const isBSEFuturesOptionsChecked = checkboxes?.BsefuturesOptions;
  const isCommoditiesCurrencies = checkboxes?.commoditiesCurrencies;
  const isCommodities = checkboxes?.commodities;
  const isMfChecked = checkboxes?.mutualFunds;

  useEffect(() => {
    if (!isCommodities && !isCommoditiesCurrencies && !isFuturesOptionsChecked) {
      setMasterDerivativeChecked(false);
    } else { setMasterDerivativeChecked(true); }
  }, [isCommodities, isCommoditiesCurrencies, isFuturesOptionsChecked])

  const [masterEquityChecked, setMasterEquityChecked] = useState(true);
  const masterEquityCheckboxHandler = (event) => {
    const checked = event.target.checked;
    setMasterEquityChecked(checked);
    setCheckboxes((prevState) => ({
      ...prevState,
      nseEquity: checked,
      bseEquity: checked
    }));

  }
  const [selectedOptions, setSelectedOptions] = useState({
    declaration3_option: "",
    declaration4_option: "",
    declaration8_option: "",
    declaration9_option: ""
  });

  const [masterDerivateChecked, setMasterDerivativeChecked] = useState(false);
  const masterDerivateCheckboxHandler = (event) => {
    const checked = event.target.checked;
    setMasterDerivativeChecked(checked);
    setCheckboxes((prevState) => ({
      ...prevState,
      futuresOptions: checked,
      BsefuturesOptions: checked,
      commoditiesCurrencies: checked,
      commodities: checked,
    }));
  }
  //setting radio buttons
  // const handleRadioChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };
  const handleRadioChange = (e, index) => {
    setSelectedOptions({
      ...selectedOptions,
      [`declaration${index + 1}_option`]: e.target.value
    });
  };

  const handleRadioChange2 = (event) => {
    setSelectedOption2(event.target.value);
  };



  //setting DDPI checkboxes
  const [masterDDpiChecked, setMasterDDpiChecked] = useState(true);
  const [ddpiCheckBoxes, setDDpiCheckBoxes] = useState({
    settlementTransaction: true,
    marginPledgeTransaction: true,
    tenderingShares: true,
    mutualFundTransaction: true
  });

  const handleDDpiMasterCheckboxChange = (event) => {
    const checked = event.target.checked;
    setMasterDDpiChecked(checked);

    setDDpiCheckBoxes({
      settlementTransaction: checked,
      marginPledgeTransaction: checked,
      tenderingShares: checked,
      mutualFundTransaction: checked
    })
  }
  const isSettlementChecked = ddpiCheckBoxes?.settlementTransaction;
  const isMarginChecked = ddpiCheckBoxes?.marginPledgeTransaction;
  const isTenderChecked = ddpiCheckBoxes?.tenderingShares;
  const ismftChecked = ddpiCheckBoxes?.mutualFundTransaction;


  useEffect(() => {
    if (!isSettlementChecked && !isMarginChecked && !isTenderChecked && !ismftChecked) {
      setMasterDDpiChecked(false)
    }
    else { setMasterDDpiChecked(true) }
  }, [isSettlementChecked, isMarginChecked, isTenderChecked, ismftChecked])

  // Handler for individual checkboxes
  const handleDDpiCheckboxChange = (event) => {
    const { name, checked } = event.target;

    // Update the state of the clicked checkbox
    setDDpiCheckBoxes((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  // set declarations checkboxes
  const [mainDeclarationChecked, setMainDeclarationChecked] = useState(true);
  const [declarationCheckboxes, setDeclarationCheckboxes] = useState({
    declaration1: true,
    declaration2: true,
    declaration3: true,
    declaration4: true,
    declaration5: true,
    declaration6: true,
    declaration7: true,
    declaration8: true,
    declaration9: true,
    declaration10: true,
    declaration11: true,
    declaration12: false,
    // declaration13: false,
  });
  const handleInputChange = (event) => {
    setDeclaration13Input(event.target.value);
  };
  const handleInputChangePEP = (event) => {
    setPEPDesc(event.target.value);
  };

  // Handler for main declaration checkbox
  const handleMainDeclarationCheckboxChange = (event) => {
    const checked = event.target.checked;
    setMainDeclarationChecked(checked);

    // Set the state of all declaration checkboxes based on the main declaration checkbox
    setDeclarationCheckboxes((prevState) => ({
      ...prevState,
      declaration1: checked,
      declaration2: checked,
      declaration3: checked,
      declaration4: checked,
      declaration5: checked,
      declaration6: checked,
      declaration7: checked,
      declaration8: checked,
      declaration9: checked,
      declaration10: checked,
      declaration11: checked,
    }));
    setSelectedOptions({
      ...selectedOptions,
      declaration3_option: checked ? "As per SEBI Regulation" : '',
      declaration4_option: checked ? "Quarterly" : "",
      declaration8_option: checked ? "Electronically" : "",
      declaration9_option: checked ? "Electronically" : ''
    });
  };

  const d1 = declarationCheckboxes?.declaration1;
  const d2 = declarationCheckboxes?.declaration2;
  const d3 = declarationCheckboxes?.declaration3;
  const d4 = declarationCheckboxes?.declaration4;
  const d5 = declarationCheckboxes?.declaration5;
  const d6 = declarationCheckboxes?.declaration6;
  const d7 = declarationCheckboxes?.declaration7;
  const d8 = declarationCheckboxes?.declaration8;
  const d9 = declarationCheckboxes?.declaration9;
  const d10 = declarationCheckboxes?.declaration10;
  const d11 = declarationCheckboxes?.declaration11;
  const d12 = declarationCheckboxes?.declaration12;
  // const d13 = declarationCheckboxes?.declaration13;

  useEffect(() => {
    if (d1 && d2 && d3 && d4 && d5 && d6 && d7 && d8 && d9 && d10 && d11) {
      setMainDeclarationChecked(true);
    } else { setMainDeclarationChecked(false); }

  }, [d1, d2, d3, d4, d5, d6, d7, d8, d9, d10, d11, d12])

  useEffect(() => {
    if (d3) {
      setSelectedOptions({
        ...selectedOptions,
        declaration3_option: "As per SEBI Regulation",
      });
    } else {
      setSelectedOptions({
        ...selectedOptions,
        declaration3_option: '',
      });
    }
  }, [d3])
  useEffect(() => {
    if (d4) {
      setSelectedOptions({
        ...selectedOptions,
        declaration4_option: "Quarterly",
      });
    } else {
      setSelectedOptions({
        ...selectedOptions,
        declaration4_option: '',
      });
    }
  }, [d4])
  useEffect(() => {
    if (d8) {
      setSelectedOptions({
        ...selectedOptions,
        declaration8_option: "Electronically",
      });
    } else {
      setSelectedOptions({
        ...selectedOptions,
        declaration8_option: '',
      });
    }
  }, [d8])
  useEffect(() => {
    if (d9) {
      setSelectedOptions({
        ...selectedOptions,
        declaration9_option: "Electronically",
      });
    } else {
      setSelectedOptions({
        ...selectedOptions,
        declaration9_option: '',
      });
    }
  }, [d9])
  useEffect(() => {
    if (d11) {
      setIsPEPChecked(true);
      setPEPDescription(true);

    } else {
      setIsPEPChecked(false);
      setPEPDescription(false);
      setShowModal(true);
    }
  }, [d11])
  // Handler for individual declaration checkboxes
  const handleDeclarationCheckboxChange = (event) => {
    setErrorMsg('');
    const { name, checked } = event.target;
    // Update the state of the clicked declaration checkbox
    setDeclarationCheckboxes((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };


  //toggle 
  const toggle = () => {
    setIsToggled(!isToggled);
  };
  const toggleDdpi = () => {
    setIsDdpiToggle(!isDdpiToggle);

  }
  const toggleDeclaration = () => {
    setIsDeclarationToggle(!isDeclarationToggle);
  }

  const TnCHandler = (e) => {
    setTnC(e.target.checked);
  }
  const handleNomineeData = (data, id) => {
    setNomineeDataList([...nomineeDataList, { id, data }]);
  };
  
  //third Nominee data

  const [errors2, setErrors2] = useState({});
  //previous code

  // const handleChange2 = (e) => {
  //   const { name, value } = e.target;
  //   setNomineeData2({ ...nomineeData2, [name]: value });
  //   if (errors2[name]) {
  //     setErrors2({ ...errors2, [name]: '' });
  //   }
  // };
  const handleChange2 = (e) => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    const aadharRegex = /^\d{12}$/;
    const { name, value } = e.target;

    setNomineeData2({ ...nomineeData2, [name]: value });
    if (errors2[name]) {
      setErrors2({ ...errors2, [name]: '' });
    }
    if (name === "identification2") {
      document.getElementById('fullName2').readOnly = false;
      setNomineeData2({
        ...nomineeData2,
        identification2: value,
        identificationNumber2: "",
        fullName2: "" // Clear the identificationNumber
      });
    }
    if (value.length === 0) {
      setErrors2({ ...errors2, [name]: '' });
      return;
    }
    if (name === "mobile2") {
      // Define the regex pattern for mobile number validation
      const mobileRegex = /^(?=[6-9])\d{10}$/;
      // Test if the entered value matches the pattern
      const isValidMobile = mobileRegex.test(value);

      // If the value is not valid, set an error message
      if (!isValidMobile) {
        setErrors2({ ...errors2, [name]: 'Please enter a valid mobile number' });
      } else {
        // Clear the error message if the value is valid
        setErrors2({ ...errors2, [name]: '' });
      }
    }
    if (name === "identificationNumber2") {
      if (nomineeData2.identification2 === "Aadhaar") {
        const isValidAadhar = aadharRegex.test(value);

        // If the value is not valid, set an error message
        if (!isValidAadhar) {
          setErrors2({ ...errors2, [name]: 'Please enter a valid Aadhar number' });
        } else {
          // Clear the error message if the value is valid
          setErrors2({ ...errors2, [name]: '' });
        }
      }
      if (nomineeData2.identification2 === "PAN") {
        const formattedPan = value.toUpperCase().replace(/[^A-Z0-9]/g, '');
        // Test if the formatted PAN number matches the pattern
        const isValidPan = panRegex.test(formattedPan);

        // If the value is not valid, set an error message
        if (!isValidPan) {
          setErrors2({ ...errors2, [name]: 'Please enter a valid PAN number' });
        } else {
          // dkycDirectGetApi(`index.php?fn=validatePanMyetrade&panNo=${formattedPan}&dob=23-10-1983`)
          //   .then((res) => {
          //     if (res.status === true) {
          //       setNomineeData2({ ...nomineeData2, 'fullName2': res.data.Name, identificationNumber2: formattedPan });
          //       document.getElementById('fullName2').readOnly = true;
          //     } else if (res.status == false) {
          //       if (res.data[0]) {
          //         setNomineeData2({ ...nomineeData2, fullName: res.data[0].Name, identificationNumber2: formattedPan });
          //         document.getElementById('fullName2').readOnly = true;
          //       }
          //     }
          //   })
          //   .catch((error) => {

          //   });
          // Clear the error message if the value is valid
          setErrors2({ ...errors2, [name]: '' });
        }
      }

    }
  };


  const handleEmailChange2 = (e) => {
    const { name, value } = e.target;
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/i;
    const isValidEmail = emailRegex.test(value);
    if (isValidEmail || value === '') {
      setNomineeData2({ ...nomineeData2, [name]: value });
      setErrors2({ ...errors2, [name]: '' });
    } else {
      setErrors2({ ...errors2, [name]: 'Please enter a valid email address' });
    }
  };

  const handlePincodeChange2 = (e) => {
    const { name, value } = e.target;
    if (e.target.value.length < 6) {
      setNomineeData2({ ...nomineeData2, pincode2: e.target.value });
      setErrors2({ ...errors2, [name]: 'Please enter 6 digit Pincode' });
      return;
    }
    setNomineeData2({ ...nomineeData2, pincode2: e.target.value })
    dkycGetApi(`/GetPincodeMaster?PinCode=${e.target.value}`)
      .then((res) => {
        if (res.success === true) {
          setErrors2({ ...errors2, [name]: '', city2: '', state2: '' });
          let inputString = res.data[0].name;
          const parts = inputString.split("/");
          setNomineeData2({
            ...nomineeData2,
            city2: parts[1],
            state2: parts[2],
            pincode2: parts[0]
          });
        }
        else {
          setErrors2({ ...errors2, [name]: 'Please enter a valid Pincode' });
          setNomineeData2({
            ...nomineeData2,
            city2: '',
            state2: '',
            pincode2: e.target.value
          })
        }
      })
      .catch(() => {
        setErrors2({ ...errors2, [name]: 'Please enter a valid Pincode' });
        setNomineeData2({
          ...nomineeData2,
          city2: '',
          state2: '',
          pincode2: e.target.value
        })
      })
  };

  const handleCheckboxChange2 = (e) => {
    const { name, checked } = e.target;
    setNomineeData2({ ...nomineeData2, [name]: checked });
  };


  //seconda Nominee data

  const [errors1, setErrors1] = useState({});
  // Handle change for input fields
  const handleChange1 = (e) => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    const aadharRegex = /^\d{12}$/;
    const { name, value } = e.target;

    setNomineeData1({ ...nomineeData1, [name]: value });

    if (errors1[name]) {
      setErrors1({ ...errors1, [name]: '' });
    }
    if (name === "identification1") {
      document.getElementById('fullName1').readOnly = false;
      setNomineeData1({
        ...nomineeData1,
        identification1: value,
        identificationNumber1: "",
        fullName1: ""// Clear the identificationNumber
      });
    }
    if (value.length === 0) {
      setErrors1({ ...errors1, [name]: '' });
      return;
    }
    if (name === "mobile1") {
      // Define the regex pattern for mobile number validation
      const mobileRegex = /^(?=[6-9])\d{10}$/;
      // Test if the entered value matches the pattern
      const isValidMobile = mobileRegex.test(value);

      // If the value is not valid, set an error message
      if (!isValidMobile) {
        setErrors1({ ...errors1, [name]: 'Please enter a valid mobile number' });
      } else {
        // Clear the error message if the value is valid
        setErrors1({ ...errors1, [name]: '' });
      }
    }
    if (name == "identificationNumber1") {
      if (nomineeData1.identification1 == "Aadhaar") {
        const isValidAadhar = aadharRegex.test(value);

        // If the value is not valid, set an error message
        if (!isValidAadhar) {
          setErrors1({ ...errors1, [name]: 'Please enter a valid Aadhar number' });
        } else {
          // Clear the error message if the value is valid
          setErrors1({ ...errors1, [name]: '' });
        }
      }
      if (nomineeData1.identification1 == "PAN") {
        const formattedPan = value.toUpperCase().replace(/[^A-Z0-9]/g, '');
        // Test if the formatted PAN number matches the pattern
        const isValidPan = panRegex.test(formattedPan);

        // If the value is not valid, set an error message
        if (!isValidPan) {
          setErrors1({ ...errors1, [name]: 'Please enter a valid PAN number' });
        } else {
          // dkycDirectGetApi(`index.php?fn=validatePanMyetrade&panNo=${formattedPan}&dob=23-10-1983`)
          //   .then((res) => {
          //     if (res.status == true) {
          //       setNomineeData1({ ...nomineeData1, fullName1: res.data.Name, identificationNumber1: formattedPan });
          //       document.getElementById('fullName1').readOnly = true;
          //     } else if (res.status == false) {
          //       if (res.data[0]) {
          //         setNomineeData1({ ...nomineeData1, fullName: res.data[0].Name, identificationNumber1: formattedPan });
          //         document.getElementById('fullName1').readOnly = true;
          //       }
          //     }
          //   })
          //   .catch((error) => {

          //   })
          // Clear the error message if the value is valid
          setErrors1({ ...errors1, [name]: '' });
        }
      }
    }
  };
  const handleEmailChange1 = (e) => {
    const { name, value } = e.target;
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/i;
    const isValidEmail = emailRegex.test(value);
    if (isValidEmail || value === '') { // Allow empty email or valid emails
      setNomineeData1({ ...nomineeData1, [name]: value });
      //   onChange(index, name, value);
      // }
      setErrors1({ ...errors1, [name]: '' }); // Clear error message if email is valid
    } else {
      setErrors1({ ...errors1, [name]: 'Please enter a valid email address' });
    }
    setNomineeData1({ ...nomineeData1, [name]: value });
  }
  const handleCheckboxChange1 = (e) => {
    const { name, checked } = e.target;
    setNomineeData1({ ...nomineeData1, [name]: checked });
  };
  const handlePincodeChange1 = (e) => {
    const { name, value } = e.target;
    if (e.target.value.length < 6) {
      setNomineeData1({ ...nomineeData1, pincode1: e.target.value });
      setErrors({ ...errors, [name]: 'Please enter 6 digit Pincode' });
      return;
    }
    setNomineeData1({ ...nomineeData1, pincode1: e.target.value });
    dkycGetApi(`/GetPincodeMaster?PinCode=${e.target.value}`)
      .then((res) => {
        if (res.success === true) {
          setErrors({ ...errors, [name]: '', city1: '', state1: '' });
          let inputString = res.data[0].name;
          const parts = inputString.split("/");
          setNomineeData1({
            ...nomineeData1,
            city1: parts[1],
            state1: parts[2],
            pincode1: parts[0]
          });
        } else {
          setErrors1({ ...errors1, [name]: 'Enter a valid Pincode' });
          setNomineeData1({
            ...nomineeData1,
            city1: '',
            state1: '',
            pincode1: e.target.value
          });
        }
      })
      .catch(() => {
        setErrors({ ...errors1, [name]: 'Enter a valid Pincode' });
        setNomineeData1({
          ...nomineeData1,
          city1: '',
          state1: '',
          pincode1: e.target.value
        });
      });
  };
  // first Nominee data
  const [relationDropdown, setRelationDropdown] = useState([]);


  const [errors, setErrors] = useState({});
  useEffect(() => {
    hasNominee === 'yes' &&
      (dkycGetApi('/GetRelationMaster')
        .then((res) => {
          setRelationDropdown(res.data)
        })
        .catch(() => { }))
  }, [hasNominee])
  useEffect(() => {
    if (nomineeData.sameAddress && hasNominee === 'yes') {
      setSmallLoaderActive(true);
      const payload = {
        mobile: mobile,
        pan_no: pan
      }
      dkycApiWithLoader('/checkRequiredJourneyData', payload, 'multipart/form-data')
        .then((res) => {
          if (res.success == true) {
            setSmallLoaderActive(false);
            setNomineeData({
              ...nomineeData, address1: res.data.result[0].PRAddr1,
              address2: res.data.result[0].PRAddr2,
              address3: res.data.result[0].PRAddr3,
              pincode: res.data.result[0].PRPinCode,
              state: res.data.result[0].PRState,
              city: res.data.result[0].PRCity,
            })
            setErrors({ ...errors, address1: '', address2: "", address3: "", pincode: "", state: "", city: "" })
          }
        })
        .catch(() => { setSmallLoaderActive(false) })
    } else {
      setNomineeData({
        ...nomineeData,
        address1: "",
        address2: "",
        address3: "",
        pincode: "",
        state: "",
        city: "",
      })
    }
  }, [nomineeData.sameAddress, hasNominee])
  useEffect(() => {
    if (nomineeData1.sameAddress1 && hasNominee === 'yes') {
      setSmallLoaderActive1(true);
      const payload = {
        mobile: mobile,
        pan_no: pan
      };
      dkycApiWithLoader('/checkRequiredJourneyData', payload, 'multipart/form-data')
        .then((res) => {
          setSmallLoaderActive1(false);
          if (res.success === true) {
            setNomineeData1({
              ...nomineeData1,
              address11: res.data.result[0].PRAddr1,
              address21: res.data.result[0].PRAddr2,
              address31: res.data.result[0].PRAddr3,
              pincode1: res.data.result[0].PRPinCode,
              state1: res.data.result[0].PRState,
              city1: res.data.result[0].PRCity,
            });
            setErrors1({ ...errors1, address11: '', address21: "", address31: "", pincode1: "", state1: "", city1: "" })
          }
        })
        .catch(() => { setSmallLoaderActive(false); });
    } else {
      setNomineeData1({
        ...nomineeData1,
        address11: "",
        address21: "",
        address31: "",
        pincode1: "",
        state1: "",
        city1: "",
      });
    }

  }, [nomineeData1.sameAddress1])
  useEffect(() => {
    if (nomineeData2.sameAddress2) {
      setSmallLoaderActive2(true);
      const payload = {
        mobile: mobile,
        pan_no: pan
      };
      dkycApiWithLoader('/checkRequiredJourneyData', payload, 'multipart/form-data')
        .then((res) => {
          setSmallLoaderActive2(false);
          if (res.success === true) {
            setNomineeData2({
              ...nomineeData2,
              address12: res.data.result[0].PRAddr1,
              address22: res.data.result[0].PRAddr2,
              address32: res.data.result[0].PRAddr3,
              pincode2: res.data.result[0].PRPinCode,
              state2: res.data.result[0].PRState,
              city2: res.data.result[0].PRCity,
            });
            setErrors2({ ...errors1, address12: '', address22: "", address32: "", pincode2: "", state2: "", city2: "" })
          }
        })
        .catch(() => { setSmallLoaderActive2(false); });
    } else {
      setNomineeData2({
        ...nomineeData2,
        address12: "",
        address22: "",
        address32: "",
        pincode2: "",
        state2: "",
        city2: "",
      });
    }
  }, [nomineeData2.sameAddress2])


  const handleChange = (e) => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    const aadharRegex = /^\d{12}$/;

    const { name, value } = e.target;
    setNomineeData({ ...nomineeData, [name]: value });

    if (errors[name]) {
      setErrors({ ...errors, [name]: '' });
    }
    if (name === "identification") {
      document.getElementById('fullName').readOnly = false;
      setNomineeData({
        ...nomineeData,
        identification: value,
        identificationNumber: "",
        fullName: ""// Clear the identificationNumber
      });
      setErrors({ ...errors, [name]: '', identificationNumber: "" });
    }
    if (value.length === 0) {
      setErrors({ ...errors, [name]: '' });
      return;
    }
    if (name === "mobile") {
      // Define the regex pattern for mobile number validation
      const mobileRegex = /^(?=[6-9])\d{10}$/;
      // Test if the entered value matches the pattern
      const isValidMobile = mobileRegex.test(value);

      // If the value is not valid, set an error message
      if (!isValidMobile) {
        setErrors({ ...errors, [name]: 'Please enter a valid mobile number' });
      } else {
        // Clear the error message if the value is valid
        setErrors({ ...errors, [name]: '' });
      }
    }
    if (name == "identificationNumber") {
      if (nomineeData.identification == "Aadhaar") {
        const isValidAadhar = aadharRegex.test(value);

        // If the value is not valid, set an error message
        if (!isValidAadhar) {
          setErrors({ ...errors, [name]: 'Please enter a valid Aadhar number' });
        } else {
          // Clear the error message if the value is valid
          setErrors({ ...errors, [name]: '' });
        }
      }
      if (nomineeData.identification == "PAN") {
        const formattedPan = value.toUpperCase().replace(/[^A-Z0-9]/g, '');
        // Test if the formatted PAN number matches the pattern
        const isValidPan = panRegex.test(formattedPan);
        // If the value is not valid, set an error message
        if (!isValidPan) {
          // Clear the error message if the value is valid
          setErrors({ ...errors, [name]: 'Please enter a valid PAN number' });
        } else {
          // dkycDirectGetApi(`index.php?fn=validatePanMyetrade&panNo=${formattedPan}&dob=23-10-1983`)
          //   .then((res) => {
          //     if (res.status == true) {
          //       setNomineeData({ ...nomineeData, fullName: res.data.Name, identificationNumber: formattedPan });
          //       document.getElementById('fullName').readOnly = true;
          //     }
          //     else if (res.status == false && res.data[0]) {
          //       setNomineeData({ ...nomineeData, fullName: res.data[0].Name, identificationNumber: formattedPan });
          //       document.getElementById('fullName').readOnly = true;
          //     } else {
          //     }
          //   })
          //   .catch((error) => {
          //     // setNomineeData({ ...nomineeData, fullName: '', identificationNumber: formattedPan });
          //     // setErrors({ ...errors, identificationNumber: "Invalid Pan Number" });
          //     // document.getElementById('fullName').readOnly = true;
          //   })
          // Clear the error message if the value is valid
          setErrors({ ...errors, [name]: '', fullName: '' });

        }
      }

    }

  };
  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/i;
    const isValidEmail = emailRegex.test(value);
    if (isValidEmail || value === '') { // Allow empty email or valid emails
      setNomineeData({ ...nomineeData, [name]: value });
      //   onChange(index, name, value);
      // }
      setErrors({ ...errors, [name]: '' }); // Clear error message if email is valid
    } else {
      setErrors({ ...errors, [name]: 'Please enter a valid email address' });
    }
    setNomineeData({ ...nomineeData, [name]: value });
  }
  const handlePincodeChange = (e) => {
    const { name, value } = e.target;
    if (e.target.value.length < 6) {
      setNomineeData({ ...nomineeData, pincode: e.target.value });
      setErrors({ ...errors, [name]: 'Please enter 6 digit Pincode' });
      return;
    } setNomineeData({ ...nomineeData, pincode: e.target.value })
    dkycGetApi(`/GetPincodeMaster?PinCode=${e.target.value}`)
      .then((res) => {
        if (res.success == true) {
          setErrors({ ...errors, [name]: '', city: '', state: '' });
          let inputString = res.data[0].name;
          const parts = inputString.split("/");
          console.log(res);
          console.log(parts);
          setNomineeData({
            ...nomineeData,
            city: parts[1],
            state: parts[2],
            pincode: parts[0]
          });
        } else {
          setErrors({ ...errors, [name]: 'Please enter a valid Pincode' });
          setNomineeData({
            ...nomineeData,
            city: '',
            state: '',
            pincode: e.target.value
          });
        }
      })
      .catch(() => {
        setErrors({ ...errors, [name]: 'Please enter a valid Pincode' });
        setNomineeData({
          ...nomineeData,
          city: '',
          state: '',
          pincode: e.target.value
        });
      })
  }
  const handleCheckboxChanges = (e) => {
    const { name, checked } = e.target;
    setNomineeData({ ...nomineeData, [name]: checked });
  };
  const onChangeHandlerNext = async () => {
    setErrorMsg('');
    if (d12 && !declaration13Input) {
      setErrorMsg('please add your past actions ');
      return;
    }
    if (hasNominee === 'yes') {
      if (Number(nomineeData.sharing) + Number(nomineeData1.sharing1) + Number(nomineeData2.sharing2) != 100) {
        setErrorMsg('Nominee Percentage should be 100');
        return;
      }
      const requiredFields = ['fullName', 'dob', 'email', 'mobile', 'sharing', 'relation', 'identification', 'identificationNumber', 'address1', 'address2', 'pincode', 'state', 'city'];
      const newErrors = {};
      let hasErrors = false;
      const validateMobile = (mobile) => {
        const mobileRegex = /^(?=[6-9])\d{10}$/;
        return mobileRegex.test(mobile);
      };
      const validatePan = (pan) => {
        const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
        return panRegex.test(pan);
      };
      const validateAadhar = (aadhar) => {
        const aadharRegex = /^\d{12}$/;
        return aadharRegex.test(aadhar);
      };
      const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/i;
        return emailRegex.test(email);
      };
      const validateDOB = (dob) => {
        return (dob < getCurrentDate())
      };
      requiredFields.forEach(field => {
        if (!nomineeData[field]) {
          newErrors[field] = 'This field is required';
          hasErrors = true;
          setIsToggledNominee(true);
        } else if (field === 'mobile' && !validateMobile(nomineeData[field])) {
          newErrors[field] = 'Please enter a valid mobile number';
          hasErrors = true;
          setIsToggledNominee(true);
        }
        else if (field === 'identificationNumber' && (nomineeData['identification'] == 'PAN') && !validatePan(nomineeData[field])) {
          newErrors[field] = 'Please enter a valid PAN number';
          hasErrors = true;
          setIsToggledNominee(true);
        }
        else if (field === 'identificationNumber' && (nomineeData['identification'] == 'Aadhaar') && !validateAadhar(nomineeData[field])) {
          newErrors[field] = 'Please enter a valid Aaadhar number';
          hasErrors = true;
          setIsToggledNominee(true);
        }
        else if (field === 'email' && !validateEmail(nomineeData[field])) {
          newErrors[field] = 'Please enter a valid Email Address';
          hasErrors = true;
          setIsToggledNominee(true);
        }
        else if (field === 'dob' && !validateDOB(nomineeData[field])) {
          newErrors[field] = 'Please enter a valid Date of Birth';
          hasErrors = true;
          setIsToggledNominee(true);
        }
      });
      if (hasErrors) {
        setErrors(newErrors);
        return; // Stop execution if there are errors
      }
      console.log('Saving nominee:', nomineeData);
      if (true) {
        const requiredFields = ['fullName1', 'dob1', 'email1', 'mobile1', 'sharing1', 'relation1', 'identification1', 'identificationNumber1', 'address11', 'address21', 'pincode1', 'state1', 'city1'];
        const hasFilledField = requiredFields.some(field => Boolean(nomineeData1[field]));
        if (hasFilledField) {
          const newErrors = {};
          let hasErrors = false;
          requiredFields.forEach(field => {
            if (!nomineeData1[field]) {
              newErrors[field] = 'This field is required';
              hasErrors = true;
            } else if (field === 'mobile1' && !validateMobile(nomineeData1[field])) {
              newErrors[field] = 'Please enter a valid mobile number';
              hasErrors = true;
              setIsToggledNominee1(true);
            }
          });
          if (hasErrors) {
            setErrors1(newErrors);
            setIsToggledNominee1(true);
            return; // Stop execution if there are errors
          }
          console.log('Saving nominee:', nomineeData1);
        }

      }
      if (true) {
        const requiredFields = ['fullName2', 'dob2', 'email2', 'mobile2', 'sharing2', 'relation2', 'identification2', 'identificationNumber2', 'address12', 'address22', 'pincode2', 'state2', 'city2'];
        const hasFilledField = requiredFields.some(field => Boolean(nomineeData2[field]));
        if (hasFilledField) {
          const newErrors = {};
          let hasErrors = false;
          requiredFields.forEach(field => {
            if (!nomineeData2[field]) {
              newErrors[field] = 'This field is required';
              hasErrors = true;
            } else if (field === 'mobile2' && !validateMobile(nomineeData2[field])) {
              newErrors[field] = 'Please enter a valid mobile number';
              hasErrors = true;
              setIsToggledNominee2(true);
            }
          });
          if (hasErrors) {
            setErrors2(newErrors);
            setIsToggledNominee2(true);
            return; // Stop execution if there are errors
          }
          console.log('Saving nominee:', nomineeData2);
        }

      }
      console.log('Saving nominee:', nomineeData, nomineeData1, nomineeData2);
    }
    const data = {
      mobile: mobile,
      ddpi: "Yes",
      t_type: "All transactions",
      nse: isNseChecked ? "NSE" : "",
      bse: isBseChecked ? "BSE" : "",
      future_option_nse:
        //  "NSEF" ,
        isFuturesOptionsChecked ? "NSEF" : " ",
      future_option_bse:
        //  "BSEF",
        isFuturesOptionsChecked ? "BSEF" : " ",
      currencies_nse:
        //  "NSEC",
        isCommoditiesCurrencies ? "NSEC" : "",
      currencies_bse:
        //  "BSEC",
        isCommoditiesCurrencies ? "BSEC" : "",
      commodity:
        isCommodities ? 'yes' : "",
      mf: isMfChecked ? 'MF' : "",
      stage: "9",
      hasNominee: hasNominee == "yes" ? 'yes' : 'no',
      declaration1: d1 ? "Yes" : "No",
      declaration2: d2 ? "Yes" : "No",
      declaration3: d3 ? "Yes" : "No",
      declaration3_option: d3 ? selectedOptions.declaration3_option : "",
      declaration4: d4 ? "Yes" : "No",
      declaration4_option: d4 ? selectedOptions.declaration4_option : '',
      declaration5: d5 ? "Yes" : "No",
      declaration6: d6 ? "Yes" : "No",
      declaration7: d7 ? "Yes" : "No",
      declaration8: d8 ? "Yes" : "No",
      declaration8_option: d8 ? selectedOptions.declaration8_option : '',
      declaration9: d9 ? "Yes" : "No",
      declaration9_option: d9 ? selectedOptions.declaration9_option : '',
      declaration10: d10 ? "Yes" : "No",
      PEP: d11 ? "Yes" : "No",
      // RPEP: d12? "Yes" : "No",
      past_action: d12 ? "Yes" : "No",
      past_action_desc: d12 ? declaration13Input : "",
      nominee_arr: [
        transformNomineeData(nomineeData, ''),
        transformNomineeData(nomineeData1, '1'),
        transformNomineeData(nomineeData2, '2')
      ]
    }
    try {
      const response = await commonPostApi('/ddpi-segment-details-web', data, null);
      if (response?.status === 200) {
        navigationStage(response?.user_data?.stage);
      }
      else {
        setErrorMsg(response?.msg ? response?.msg : 'Something went wrong!!');
      }

    } catch (error) {
      setErrorMsg(error);
    }

  }

  return (<>
    <section className="main-wraper progress-inner">
      <StepsHeader
        step={5}
      />
      <AsideWallpaper
        color={'green'}
        descriptionHeader={''}
        backGroundImageUrl={"images/trading-and-investment.svg"}
      />
      <div className="body-content-box">
        <div className="login-box">
          <div className="login-box-wrap mw-100">
            <div className="">
              <h1 className="title-1">Trading &amp; Investment preferences </h1>
            </div>
            <div className="mw-400 block-center mb-3">
              <p className="para-1">Segment Details - 1/2</p>
            </div>
            <div className="input-box">
              <div className="terms-selection-box">
                <div className="terms-selection-wrap mb-0 mh-100">
                  <div className="sub-checkbox-menu scm-1">
                    <p className="text-left para-1 position-relative pe-2 ">
                      <label className="d-flex">
                        <input className="mt-1"
                          type="checkbox"
                          checked={masterTradeChecked}
                          onChange={handleMasterCheckboxChange}
                        />
                        <span className="ml-2 d-inline-block" >
                          I agree to trade in these segments
                        </span>
                      </label>
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className={`checkbox-dropdown-icon multiselect-bottom-icon  ${isToggled ? 'toggle-open' : ''}`}
                        onClick={toggle}
                      />
                    </p>
                  </div>
                  <div
                    className={`sub-checkbox-wrap scw-1`}

                    style={{ display: isToggled ? "block" : "none" }}
                  >
                    <p className="text-left para-13">
                      <label className="d-flex">
                        <input type="checkbox"
                          name="Equity"
                          //asked by Tripti maam to default set equity to checked on 9/9/23
                          // checked={masterEquityChecked}
                          checked={true}
                          onChange={masterEquityCheckboxHandler} />
                        <span className="ml-2 d-inline-block">Equity</span>
                      </label>
                    </p>
                    {/* <p className="text-left para-13 ps-4">
                      <label className="d-flex">
                        <input type="checkbox"
                          name="nseEquity"
                          // checked={checkboxes.nseEquity}
                          checked={true}
                          onChange={handleCheckboxChange} />
                        <span className="ml-2 d-inline-block">NSE Equity</span>
                      </label>
                    </p>
                    <p className="text-left para-13 ps-4">
                      <label className="d-flex">
                        <input type="checkbox"
                          name='bseEquity'
                          // checked={checkboxes.bseEquity}
                          checked={true}
                          onChange={handleCheckboxChange} />
                        <span className="ml-2 d-inline-block" >BSE Equity</span>
                      </label>
                    </p> */}
                    <p className="text-left para-13">
                      <label className="d-flex">
                        <input type="checkbox"
                          name="Derivatives"
                          checked={masterDerivateChecked}
                          onChange={masterDerivateCheckboxHandler} />
                        <span className="ml-2 d-inline-block">Derivatives</span>
                      </label>
                    </p>
                    <p className="text-left para-13 ps-4">
                      <label className="d-flex">
                        <input type="checkbox"
                          name='futuresOptions'
                          checked={checkboxes.futuresOptions}
                          onChange={handleCheckboxChange} />
                        <span className="ml-2 d-inline-block">
                          Futures &amp; Options
                        </span>
                      </label>
                    </p>
                    {/* <p className="text-left para-13 ps-4">
                      <label className="d-flex">
                        <input type="checkbox"
                          name='BsefuturesOptions'
                          checked={checkboxes.BsefuturesOptions}
                          onChange={handleCheckboxChange} />
                        <span className="ml-2 d-inline-block">
                          BSE Futures &amp; Options
                        </span>
                      </label>
                    </p> */}
                    <p className="text-left para-13 ps-4">
                      <label className="d-flex">
                        <input type="checkbox"
                          name='commoditiesCurrencies'
                          checked={checkboxes.commoditiesCurrencies}
                          onChange={handleCheckboxChange} />
                        <span className="ml-2 d-inline-block">
                          Currencies
                        </span>
                      </label>
                    </p>
                    <p className="text-left para-13 ps-4">
                      <label className="d-flex">
                        <input type="checkbox"
                          name='commodities'
                          checked={checkboxes.commodities}
                          onChange={handleCheckboxChange} />
                        <span className="ml-2 d-inline-block" >Commodities</span>
                      </label>
                    </p>
                    <p className="text-left para-13 ">
                      <label className="d-flex">
                        <input type="checkbox"
                          name='mutualFunds'
                          checked={checkboxes.mutualFunds}
                          onChange={handleCheckboxChange} />
                        <span className="ml-2 d-inline-block" >Mutual Funds</span>
                      </label>
                    </p>
                  </div>
                  <div className="sub-checkbox-menu scm-2 ">
                    <p className="text-left para-1 position-relative pe-2">
                      <label className="d-flex ">
                        <input type="checkbox" className="mt-1" checked={masterDDpiChecked} onChange={handleDDpiMasterCheckboxChange} />
                        <span className="ml-2 d-inline-block" >
                          I provide my authorisation to Bigul for Demat Debit and
                          Pledge Instruction
                        </span>
                      </label>
                      <span>
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className={`checkbox-dropdown-icon multiselect-bottom-icon  ${isDdpiToggle ? 'toggle-open' : ''}`}
                          onClick={toggleDdpi}
                        />
                      </span>
                      <i className="checkbox-dropdown-icon" />
                    </p>
                  </div>
                  <div
                    className="sub-checkbox-wrap scw-2"
                    style={{ display: isDdpiToggle ? "block" : "none" }}
                  >
                    <p className="text-left para-13">
                      <label className="d-flex">
                        <input type="checkbox"
                          checked={ddpiCheckBoxes.settlementTransaction}
                          name="settlementTransaction"
                          onChange={handleDDpiCheckboxChange} />
                        <span className="ml-2 d-inline-block">
                          Settlement Transactions
                        </span>
                      </label>
                    </p>
                    <p className="text-left para-13">
                      <label className="d-flex">
                        <input type="checkbox"
                          checked={ddpiCheckBoxes.marginPledgeTransaction}
                          name="marginPledgeTransaction"
                          onChange={handleDDpiCheckboxChange} />
                        <span className="ml-2 d-inline-block">
                          Margin Pledge Transactions{" "}
                        </span>
                      </label>
                    </p>

                    <p className="text-left para-13">
                      <label className="d-flex">
                        <input
                          type="checkbox"
                          checked={ddpiCheckBoxes.tenderingShares}
                          name='tenderingShares'
                          onChange={handleDDpiCheckboxChange}
                        />
                        <span className="ml-2 d-inline-block">
                          Tendering Shares{" "}
                        </span>
                      </label>
                    </p>
                    <p className="text-left para-13">
                      <label className="d-flex">
                        <input
                          type="checkbox"
                          checked={ddpiCheckBoxes.mutualFundTransaction}
                          name='mutualFundTransaction'
                          onChange={handleDDpiCheckboxChange}
                        />
                        <span className="ml-2 d-inline-block">
                          Mutual Fund Transactions{" "}
                        </span>
                      </label>
                    </p>

                  </div>
                  <div className="sub-checkbox-menu scm-3">
                    <p className="text-left para-1 position-relative pe-2">
                      <label className="d-flex ">
                        <input
                          type="checkbox"
                          checked={mainDeclarationChecked}
                          onChange={handleMainDeclarationCheckboxChange}
                          className='mt-1'
                        // disabled={!masterTradeChecked}
                        />
                        <span className="ml-2 d-inline-block">
                          Declaration and preferences
                        </span>
                      </label>
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className={`checkbox-dropdown-icon multiselect-bottom-icon  ${isDeclarationToggle ? 'toggle-open' : ''}`}
                        onClick={toggleDeclaration}
                      />
                      {/* <i className="checkbox-dropdown-icon" /> */}
                    </p>
                  </div>
                  <div
                    className="sub-checkbox-wrap scw-3"
                    style={{ display: isDeclarationToggle ? "block" : "none" }}
                  >{declarations.map((declaration, index) => (
                    <p className="text-left para-13" key={index}>
                      <label className="d-flex">
                        <input
                          type="checkbox"
                          name={`declaration${index + 1}`} // Dynamically set the name attribute
                          checked={declarationCheckboxes[`declaration${index + 1}`]} // Use dynamic key to access state
                          onChange={handleDeclarationCheckboxChange}
                        />
                        <span className="ml-2 d-inline-block">{declaration[`declaration${index + 1}`]}</span> {/* Use dynamic key to access declaration */}
                      </label>
                      {/* Render options if they exist */}
                      {declaration.options && declaration.options.length > 0 && (
                        <div>
                          {declaration.options.map((option, optionIndex) => (
                            <label className="radio-container" key={optionIndex}>
                              <input
                                type="radio"
                                name={`radio${index}`} // Use a unique name for each group of radio buttons
                                checked={selectedOptions[`declaration${index + 1}_option`] === option} // Check if this option is selected
                                value={option}
                                onChange={(e) => handleRadioChange(e, index)} // Handle change
                              />
                              <span className="radiomark"></span>
                              <span className="radiomark-text">{option}</span>
                            </label>
                          ))}
                        </div>
                      )}
                      {index === 11 && declarationCheckboxes[`declaration${index + 1}`] && (
                        <input
                          type="text"
                          value={declaration13Input}
                          onChange={handleInputChange}
                          placeholder="Enter details for past action"
                          style={{ width: "100%" }}
                        />
                      )}
                    </p>
                  ))}
                    {/* {!isPEPChecked && (
                          // <Modal onClose={handleCloseModal} />
                      )} */}
                  </div>
                  <div className="i-accept-terms-box">
                    <label className="text-left d-flex" style={{ marginBottom: "5px" }} >
                      <input type="checkbox" checked={TnC} onChange={TnCHandler} disabled={!mainDeclarationChecked} />
                      <span className="para-13 ml-1 d-inline-block mr-4">
                        I accept the <a href='https://bigul.co/terms-and-conditions/kyc-terms-and-conditions/' target="_blank" style={{ textDecoration: "none", color: "#5367FC" }}>Terms &amp; Conditions</a> and <a href='https://bigul.co/pricing' target="_blank" style={{ textDecoration: "none", color: "#5367FC" }}> Pricing Plan</a>
                      </span>

                    </label>
                  </div>
                  <div>
                    <p style={{
                      fontSize: '12px',
                      fontWeight: 400,
                      color: '#5367FC',
                      textAlign: 'left'
                    }}>Selecting the Derivative Option requires submission of income proof.</p>
                  </div>

                  <div className="nominee-box pt-3">
                    <p>Do you have a nominee?</p>
                    <div className="form-group mb-3">

                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          value="no"
                          checked={hasNominee === 'no'}
                          onChange={handleNomineeRadioChange}
                        />
                        <label className="form-check-label" htmlFor="inlineRadio2">
                          No
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          value="yes"
                          checked={hasNominee === 'yes'}
                          onChange={handleNomineeRadioChange}
                        />
                        <label className="form-check-label" htmlFor="inlineRadio1">
                          Yes
                        </label>
                      </div>
                    </div>
                    {hasNominee === 'yes' && (
                      <div className="tab-box">
                        <div id="accordion" className=''>
                          <div className="card bg-white w-100 p-2 mb-3">
                            <div className="card-header py-0 px-0">
                              <a className="btn d-block py-2 px-3 text-left" data-bs-toggle="collapse" href="#collapseOne"
                                onClick={handleToggleNominee}>
                                Nominee 1 *
                                <FontAwesomeIcon
                                  icon={faChevronDown}
                                  className={`checkbox-dropdown-icon multiselect-bottom-icon-2  ${isToggledNominee ? 'toggle-open' : ''}`}
                                  onClick={handleToggleNominee}
                                />
                              </a>
                            </div>
                            <div id="collapseOne"
                              className={`collapse ${isToggledNominee ? 'show' : ""}`}
                              data-bs-parent="#accordion"
                            >
                              <div className="card-body">
                                {/* Nominee1 */}
                                <div>
                                  <div className="row custom-css-row">
                                    <div className="form-group col-xl-6">
                                      <label>Identification</label>
                                      <select
                                        className="form-control"
                                        name="identification"
                                        value={nomineeData.identification}
                                        onChange={handleChange}
                                      >
                                        <option value="">Select Proof of Identity</option>
                                        <option value="Aadhaar">Aadhaar</option>
                                        <option value="PAN">PAN</option>
                                        {/* Add more options as needed */}
                                      </select>
                                      {errors.identification && <div className="input-error-msg">{errors.identification}</div>}
                                    </div>
                                    {nomineeData.identification && (
                                      <div className="form-group col-xl-6">
                                        <label>{`${nomineeData.identification} Number`}:</label>
                                        <ReactInputMask
                                          mask={`${nomineeData.identification === "PAN" ? "aaaaa9999a" : "999999999999"}`} maskChar={null}
                                          type="text"
                                          name="identificationNumber"
                                          className="form-control"
                                          value={nomineeData.identificationNumber.toUpperCase()}
                                          onChange={handleChange}
                                          placeholder={`Enter ${nomineeData.identification}`}
                                        />
                                        {errors.identificationNumber && <div className="input-error-msg">{errors.identificationNumber}</div>}
                                      </div>
                                    )}
                                    <div className="form-group col-xl-6">
                                      <label>Full Name</label>
                                      <input
                                        type="text"
                                        name="fullName"
                                        id='fullName'
                                        placeholder="Enter Full Name"
                                        className="form-control"
                                        value={nomineeData.fullName}
                                        onChange={handleChange}
                                      />
                                      {errors.fullName && <div className="input-error-msg">{errors.fullName}</div>}
                                    </div>
                                    <div className="form-group col-xl-6 form-group-dob">
                                      <label>DOB</label>
                                      <span>DOB : </span>
                                      <input
                                        type="date"
                                        name="dob"
                                        placeholder='DOB'
                                        className="form-control"
                                        value={nomineeData.dob}
                                        onChange={handleChange}
                                        max={getCurrentDate()}
                                      />
                                      {errors.dob && <div className="input-error-msg">{errors.dob}</div>}
                                    </div>
                                    <div className="form-group col-xl-6">
                                      <label>Email</label>
                                      <input
                                        type="text"
                                        name="email"
                                        placeholder="Enter Email"
                                        className="form-control"
                                        value={nomineeData.email}
                                        onChange={handleEmailChange}
                                      />
                                      {errors.email && <div className="input-error-msg">{errors.email}</div>}
                                    </div>
                                    <div className="form-group col-xl-6">
                                      <label>Mobile</label>
                                      <ReactInputMask
                                        mask="9999999999" maskChar={null}
                                        type="text"
                                        name="mobile"
                                        placeholder="Enter Mobile Number"
                                        className="form-control"
                                        value={nomineeData.mobile}
                                        onChange={handleChange}
                                      />
                                      {errors.mobile && <div className="input-error-msg">{errors.mobile}</div>}
                                    </div>
                                    <div className="form-group col-xl-6">
                                      <label>Relation</label>
                                      <select
                                        className="form-control"
                                        name="relation"
                                        value={nomineeData.relation}
                                        onChange={handleChange}
                                      > <option value="">Select Relation</option>
                                        {relationDropdown.map(option => (
                                          <option key={option.id} value={option.id}>{option.name}</option>
                                        ))}
                                      </select>
                                      {errors.relation && <div className="input-error-msg">{errors.relation}</div>}
                                    </div>
                                    <div className="form-group col-xl-6">
                                      <label>Nominee Percentage</label>
                                      <ReactInputMask
                                        mask={'999'} maskChar={null}
                                        type="text"
                                        name="sharing"
                                        placeholder="Enter Nominee Percentage"
                                        className="form-control"
                                        value={nomineeData.sharing}
                                        onChange={handleChange}
                                      // onWheel={(e) => { e.target.blur() }}

                                      />
                                      {errors.sharing && <div className="input-error-msg">{errors.sharing}</div>}
                                    </div>
                                    <div className="form-group col-xl-12">
                                      <label className="d-flex pr-2">
                                        <input
                                          type="checkbox"
                                          className="mt-1"
                                          name="sameAddress"
                                          checked={nomineeData.sameAddress}
                                          onChange={handleCheckboxChanges}
                                        />
                                        <span className="ml-2 loader-img-20 ">Same as current address &nbsp;
                                          <SmallLoader
                                            isLoading={smallLoaderActive}
                                          /></span>
                                      </label>
                                    </div>
                                    <div className="form-group col-xl-6">
                                      <label>Address 1</label>
                                      <input
                                        type="text"
                                        name="address1"
                                        placeholder="Enter Address Line 1"
                                        className="form-control"
                                        value={nomineeData.address1}
                                        onChange={handleChange}
                                      />
                                      {errors.address1 && <div className="input-error-msg">{errors.address1}</div>}
                                    </div>
                                    <div className="form-group col-xl-6">
                                      <label>Address 2</label>
                                      <input
                                        type="text"
                                        name="address2"
                                        placeholder="Enter Address Line 2"
                                        className="form-control"
                                        value={nomineeData.address2}
                                        onChange={handleChange}
                                      />
                                      {errors.address2 && <div className="input-error-msg">{errors.address2}</div>}
                                    </div>
                                    <div className="form-group col-xl-6">
                                      <label>Address 3</label>
                                      <input
                                        type="text"
                                        name="address3"
                                        placeholder="Enter Address Line 3"
                                        className="form-control"
                                        value={nomineeData.address3}
                                        onChange={handleChange}
                                      />
                                    </div>
                                    {/* )} */}
                                    <div className="form-group col-xl-6">
                                      <label>Pincode</label>
                                      <ReactInputMask
                                        mask={"999999"} maskChar={null}
                                        type="text"
                                        name="pincode"
                                        placeholder="Enter Pincode"
                                        className="form-control"
                                        value={nomineeData.pincode}
                                        onChange={handlePincodeChange}
                                      /> {errors.pincode && <div className="input-error-msg">{errors.pincode}</div>}

                                    </div>
                                    <div className="form-group col-xl-6">
                                      <label>State</label>
                                      <input
                                        type="text"
                                        name="state"
                                        placeholder="Enter State"
                                        className="form-control"
                                        value={nomineeData.state}
                                        onChange={handleChange}
                                        readOnly
                                      />
                                      {errors.state && <div className="input-error-msg">{errors.state}</div>}
                                    </div>
                                    <div className="form-group col-xl-6">
                                      <label>City</label>
                                      <input
                                        type="text"
                                        name="city"
                                        placeholder="Enter City"
                                        className="form-control"
                                        value={nomineeData.city}
                                        onChange={handleChange}
                                        readOnly
                                      />
                                      {errors.city && <div className="input-error-msg">{errors.city}</div>}

                                    </div>
                                    {/* <div className="form-group col-xl-12">
                <button className="save-btn" onClick={handleSave}>{`Add Nominee ${id}`}</button>
            </div> */}
                                  </div>
                                </div>
                                {/* <Nominee index={0} id = {1} onNomineeData={handleNomineeData}/> */}
                              </div>
                            </div>
                          </div>
                          <div className="card bg-white w-100 p-2 mb-3" >
                            <div className="card-header py-0 px-0">
                              <a
                                className="collapsed btn d-block py-2 px-3 text-left"
                                data-bs-toggle="collapse"
                                href="#collapseTwo"
                                onClick={handleToggleNominee1}>

                                Nominee 2
                                <FontAwesomeIcon
                                  icon={faChevronDown}
                                  className={`checkbox-dropdown-icon multiselect-bottom-icon-2  ${isToggledNominee1 ? 'toggle-open' : ''}`}
                                  onClick={handleToggleNominee1}
                                />
                              </a>
                            </div>
                            <div id="collapseTwo"
                              className={`collapse ${isToggledNominee1 ? 'show' : ""}`}
                              data-bs-parent="#accordion">
                              <div className="card-body">
                                {/* nominee 2 */}
                                <div>
                                  <div className="row custom-css-row">

                                    <div className="form-group col-xl-6">
                                      <label>Identification</label>
                                      <select
                                        className="form-control"
                                        name="identification1"
                                        value={nomineeData1.identification1}
                                        onChange={handleChange1}
                                      >
                                        <option value="">Select Identification Type</option>
                                        <option value="Adhaar">Adhaar</option>
                                        <option value="PAN">PAN</option>
                                        {/* Add more options as needed */}
                                      </select>
                                      {errors1.identification1 && <div className="input-error-msg">{errors1.identification1}</div>}
                                    </div>
                                    {nomineeData1.identification1 && (<div className="form-group col-xl-6">
                                      <label>{`${nomineeData1.identification1} Number`}:</label>
                                      <ReactInputMask
                                        mask={`${nomineeData1.identification1 === "PAN" ? "aaaaa9999a" : "999999999999"}`} maskChar={null}
                                        type="text"
                                        name="identificationNumber1"
                                        className="form-control"
                                        value={nomineeData1.identificationNumber1.toUpperCase()}
                                        onChange={handleChange1}
                                        placeholder={`Enter ${nomineeData1.identification1}`}
                                      />
                                      {errors1.identificationNumber1 && <div className="input-error-msg">{errors1.identificationNumber1}</div>}
                                    </div>
                                    )}
                                    <div className="form-group col-xl-6">
                                      <label>Full Name</label>
                                      <input
                                        type="text"
                                        name="fullName1"
                                        id='fullName1'
                                        placeholder="Enter Full Name"
                                        className="form-control"
                                        value={nomineeData1.fullName1}
                                        onChange={handleChange1}
                                      />
                                      {errors1.fullName1 && <div className="input-error-msg">{errors1.fullName1}</div>}
                                    </div>
                                    <div className="form-group col-xl-6 form-group-dob">
                                      <label>DOB</label>
                                      <span>DOB : </span>
                                      <input
                                        type="date"
                                        name="dob1"
                                        placeholder='DOB'
                                        className="form-control"
                                        value={nomineeData1.dob1}
                                        onChange={handleChange1}
                                        max={getCurrentDate()}
                                      />
                                      {errors1.dob1 && <div className="input-error-msg">{errors1.dob1}</div>}
                                    </div>
                                    <div className="form-group col-xl-6">
                                      <label>Email</label>
                                      <input
                                        type="text"
                                        name="email1"
                                        placeholder="Enter Email"
                                        className="form-control"
                                        value={nomineeData1.email1}
                                        onChange={handleEmailChange1}
                                      />
                                      {errors1.email1 && <div className="input-error-msg">{errors1.email1}</div>}
                                    </div>
                                    <div className="form-group col-xl-6">
                                      <label>Mobile</label>
                                      <ReactInputMask
                                        mask="9999999999" maskChar={null}
                                        type="text"
                                        name="mobile1"
                                        placeholder="Enter Mobile Number"
                                        className="form-control"
                                        value={nomineeData1.mobile1}
                                        onChange={handleChange1}
                                      />
                                      {errors1.mobile1 && <div className="input-error-msg">{errors1.mobile1}</div>}
                                    </div>

                                    <div className="form-group col-xl-6">
                                      <label>Relation</label>
                                      <select
                                        className="form-control"
                                        name="relation1"
                                        value={nomineeData1.relation1}
                                        onChange={handleChange1}
                                      > <option value="">Select Relation</option>
                                        {relationDropdown.map(option => (
                                          <option key={option.id} value={option.id}>{option.name}</option>
                                        ))}
                                      </select>
                                      {errors1.relation1 && <div className="input-error-msg">{errors1.relation1}</div>}
                                    </div>
                                    <div className="form-group col-xl-6">
                                      <label>Nominee Percentage</label>
                                      <ReactInputMask
                                        mask={'99'} maskChar={null}
                                        type="text"
                                        name="sharing1"
                                        placeholder="Enter Nominee Percentage"
                                        className="form-control"
                                        value={nomineeData1.sharing1}
                                        onChange={handleChange1}

                                      />
                                      {errors.sharing && <div className="input-error-msg">{errors.sharing}</div>}
                                    </div>
                                    <div className="form-group col-xl-12">
                                      <label className="d-flex pr-2">
                                        <input
                                          type="checkbox"
                                          className="mt-1"
                                          name="sameAddress1"
                                          checked={nomineeData1.sameAddress1}
                                          onChange={handleCheckboxChange1}
                                        />
                                        <span className="ml-2 loader-img-20">Same as current address
                                          <SmallLoader
                                            isLoading={smallLoaderActive1}
                                          /></span>
                                      </label>
                                    </div>
                                    <div className="form-group col-xl-6">
                                      <label>Address 1</label>
                                      <input
                                        type="text"
                                        name="address11"
                                        placeholder="Enter Address Line 1"
                                        className="form-control"
                                        value={nomineeData1.address11}
                                        onChange={handleChange1}
                                      />
                                      {errors1.address11 && <div className="input-error-msg">{errors1.address11}</div>}
                                    </div>
                                    <div className="form-group col-xl-6">
                                      <label>Address 2</label>
                                      <input
                                        type="text"
                                        name="address21"
                                        placeholder="Enter Address Line 2"
                                        className="form-control"
                                        value={nomineeData1.address21}
                                        onChange={handleChange1}
                                      />
                                      {errors1.address21 && <div className="input-error-msg">{errors1.address21}</div>}
                                    </div>
                                    <div className="form-group col-xl-6">
                                      <label>Address 3</label>
                                      <input
                                        type="text"
                                        name="address31"
                                        placeholder="Enter Address Line 3"
                                        className="form-control"
                                        value={nomineeData1.address31}
                                        onChange={handleChange1}
                                      />
                                    </div>
                                    <div className="form-group col-xl-6">
                                      <label>Pincode</label>
                                      <ReactInputMask
                                        mask={"999999"} maskChar={null}
                                        type="text"
                                        name="pincode1"
                                        placeholder="Enter Pincode"
                                        className="form-control"
                                        value={nomineeData1.pincode1}
                                        onChange={handlePincodeChange1}
                                      /> {errors1.pincode1 && <div className="input-error-msg">{errors1.pincode1}</div>}

                                    </div>
                                    <div className="form-group col-xl-6">
                                      <label>State</label>
                                      <input
                                        type="text"
                                        name="state1"
                                        placeholder="Enter State"
                                        className="form-control"
                                        value={nomineeData1.state1}
                                        onChange={handleChange1}
                                        readOnly
                                      />
                                      {errors1.state1 && <div className="input-error-msg">{errors1.state1}</div>}
                                    </div>
                                    <div className="form-group col-xl-6">
                                      <label>City</label>
                                      <input
                                        type="text"
                                        name="city1"
                                        placeholder="Enter City"
                                        className="form-control"
                                        value={nomineeData1.city1}
                                        onChange={handleChange1}
                                        readOnly
                                      />
                                      {errors1.city1 && <div className="input-error-msg">{errors1.city1}</div>}

                                    </div>
                                  </div>
                                </div>
                                {/* <Nominee index={1} /> */}
                              </div>
                            </div>
                          </div>
                          <div className="card bg-white w-100 p-2 mb-3">
                            <div className="card-header py-0 px-0">
                              <a
                                className="collapsed btn d-block py-2 px-3 text-left"
                                data-bs-toggle="collapse"
                                href="#collapseThree"
                                onClick={handleToggleNominee2}>
                                Nominee 3
                                <FontAwesomeIcon
                                  icon={faChevronDown}
                                  className={`checkbox-dropdown-icon multiselect-bottom-icon-2  ${isToggledNominee2 ? 'toggle-open' : ''}`}
                                  onClick={handleToggleNominee2}
                                />
                              </a>
                              {/* <button>Remove Nominee</button> */}
                            </div>
                            <div id="collapseThree"
                              className={`collapse ${isToggledNominee2 ? 'show' : ""}`}
                              data-bs-parent="#accordion">
                              <div className="card-body">
                                <div>
                                  <div className="row custom-css-row">
                                    <div className="form-group col-xl-6">
                                      <label>Identification</label>
                                      <select
                                        className="form-control"
                                        name="identification2"
                                        value={nomineeData2.identification2}
                                        onChange={handleChange2}
                                      >
                                        <option value="">Select Identification Type</option>
                                        <option value="Aadhaar">Aadhaar</option>
                                        <option value="PAN">PAN</option>
                                        {/* Add more options as needed */}
                                      </select>
                                      {errors2.identification2 && <div className="input-error-msg">{errors2.identification2}</div>}
                                    </div>
                                    {nomineeData2.identification2 && (
                                      <div className="form-group col-xl-6">
                                        <label>{`${nomineeData2.identification2} Number`}:</label>
                                        <ReactInputMask
                                          mask={`${nomineeData2.identification2 === "PAN" ? "aaaaa9999a" : "999999999999"}`} maskChar={null}
                                          type="text"
                                          name="identificationNumber2"
                                          className="form-control"
                                          value={nomineeData2.identificationNumber2.toUpperCase()}
                                          onChange={handleChange2}
                                          placeholder={`Enter ${nomineeData2.identification2}`}
                                        />
                                        {errors2.identificationNumber2 && <div className="input-error-msg">{errors2.identificationNumber2}</div>}
                                      </div>
                                    )}
                                    <div className="form-group col-xl-6">
                                      <label>Full Name</label>
                                      <input
                                        type="text"
                                        name="fullName2"
                                        placeholder="Enter Full Name"
                                        id='fullName2'
                                        className="form-control"
                                        value={nomineeData2.fullName2}
                                        onChange={handleChange2}
                                      />
                                      {errors2.fullName2 && <div className="input-error-msg">{errors2.fullName2}</div>}
                                    </div>
                                    <div className="form-group col-xl-6 form-group-dob">
                                      <label>DOB</label>
                                      <span>DOB : </span>
                                      <input
                                        type="date"
                                        name="dob2"
                                        placeholder='DOB'
                                        className="form-control"
                                        value={nomineeData2.dob2}
                                        onChange={handleChange2}
                                        max={getCurrentDate()}
                                      />
                                      {errors2.dob2 && <div className="input-error-msg">{errors2.dob2}</div>}
                                    </div>
                                    <div className="form-group col-xl-6">
                                      <label>Email</label>
                                      <input
                                        type="text"
                                        name="email2"
                                        placeholder="Enter Email"
                                        className="form-control"
                                        value={nomineeData2.email2}
                                        onChange={handleEmailChange2}
                                      />
                                      {errors2.email2 && <div className="input-error-msg">{errors2.email2}</div>}
                                    </div>
                                    <div className="form-group col-xl-6">
                                      <label>Mobile</label>
                                      <ReactInputMask
                                        mask="9999999999" maskChar={null}
                                        type="text"
                                        name="mobile2"
                                        placeholder="Enter Mobile Number"
                                        className="form-control"
                                        value={nomineeData2.mobile2}
                                        onChange={handleChange2}
                                        minLength={10}
                                      />
                                      {errors2.mobile2 && <div className="input-error-msg">{errors2.mobile2}</div>}
                                    </div>

                                    <div className="form-group col-xl-6">
                                      <label>Relation</label>
                                      <select
                                        className="form-control"
                                        name="relation2"
                                        value={nomineeData2.relation2}
                                        onChange={handleChange2}
                                      > <option value="">Select Relation</option>
                                        {relationDropdown.map(option => (
                                          <option key={option.id} value={option.id}>{option.name}</option>
                                        ))}
                                      </select>
                                      {errors2.relation2 && <div className="input-error-msg">{errors2.relation2}</div>}
                                    </div>
                                    <div className="form-group col-xl-6">
                                      <label>Nominee Percentage</label>
                                      <ReactInputMask
                                        mask={'99'} maskChar={null}
                                        type="text"
                                        name="sharing2"
                                        placeholder="Enter Nominee Percentage"
                                        className="form-control"
                                        value={nomineeData2.sharing2}
                                        onChange={handleChange2}
                                        max={100}
                                      />
                                      {errors.sharing && <div className="input-error-msg">{errors.sharing}</div>}
                                    </div>
                                    <div className="form-group col-xl-12">
                                      <label className="d-flex pr-2">
                                        <input
                                          type="checkbox"
                                          className="mt-1"
                                          name="sameAddress2"
                                          checked={nomineeData2.sameAddress2}
                                          onChange={handleCheckboxChange2}
                                        />
                                        <span className="ml-2 loader-img-20">Same as current address
                                          <SmallLoader
                                            isLoading={smallLoaderActive2}
                                          /></span>
                                      </label>
                                    </div>
                                    <div className="form-group col-xl-6">
                                      <label>Address 1</label>
                                      <input
                                        type="text"
                                        name="address12"
                                        placeholder="Enter Address Line 1"
                                        className="form-control"
                                        value={nomineeData2.address12}
                                        onChange={handleChange2}
                                      />
                                      {errors2.address12 && <div className="input-error-msg">{errors2.address12}</div>}
                                    </div>
                                    <div className="form-group col-xl-6">
                                      <label>Address 2</label>
                                      <input
                                        type="text"
                                        name="address22"
                                        placeholder="Enter Address Line 2"
                                        className="form-control"
                                        value={nomineeData2.address22}
                                        onChange={handleChange2}
                                      />
                                      {errors2.address22 && <div className="input-error-msg">{errors2.address22}</div>}
                                    </div>
                                    <div className="form-group col-xl-6">
                                      <label>Address 3</label>
                                      <input
                                        type="text"
                                        name="address32"
                                        placeholder="Enter Address Line 3"
                                        className="form-control"
                                        value={nomineeData2.address32}
                                        onChange={handleChange2}
                                      />
                                    </div>
                                    <div className="form-group col-xl-6">
                                      <label>Pincode</label>
                                      <ReactInputMask
                                        mask={"999999"} maskChar={null}
                                        type="text"
                                        name="pincode2"
                                        placeholder="Enter Pincode"
                                        className="form-control"
                                        value={nomineeData2.pincode2}
                                        onChange={handlePincodeChange2}
                                      /> {errors2.pincode2 && <div className="input-error-msg">{errors2.pincode2}</div>}
                                    </div>
                                    <div className="form-group col-xl-6">
                                      <label>State</label>
                                      <input
                                        type="text"
                                        name="state2"
                                        placeholder="Enter State"
                                        className="form-control"
                                        value={nomineeData2.state2}
                                        onChange={handleChange2}
                                        readOnly
                                      />
                                      {errors2.state2 && <div className="input-error-msg">{errors2.state2}</div>}
                                    </div>
                                    <div className="form-group col-xl-6">
                                      <label>City</label>
                                      <input
                                        type="text"
                                        name="city2"
                                        placeholder="Enter City"
                                        className="form-control"
                                        value={nomineeData2.city2}
                                        onChange={handleChange2}
                                        readOnly
                                      />
                                      {errors2.city2 && <div className="input-error-msg">{errors2.city2}</div>}
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {errorMsg && <div className='input-error-msg text-center'>{errorMsg}</div>}
          <div className="btn-action-box text-center pb-3">
            <button className={`btn btn-${TnC && masterTradeChecked && mainDeclarationChecked ? 'primary' : 'secondary'} btn-full mb-2`} onClick={onChangeHandlerNext} disabled={!(TnC && masterTradeChecked && mainDeclarationChecked)}>Continue to Next Step
              {/* {nextButtonDesc} */}
              {/* {checkboxes.futuresOptions === true || checkboxes.commoditiesCurrencies === true ? 'next step' : 'Next Selfie'} */}
            </button>
          </div>
        </div>
      </div>
    </section >
    <Modal
      title=""
      className="w-full p-[33px]"
      width={600}
      open={showModal}
      centered={true}
      onCancel={() => modalToggle()}
      footer={null}
    >
      <div className="mt-2 mb-1.5 flex flex-col items-center">

        <div className="mb-[5px] text-xl font-medium font-poppinsMedium">
         {PEPDesc}
          </div>

      </div>
    </Modal>
  </>)
}
export default TradingAndInvestmentPreferences;
